/*
	const prodAdhesives = new productAdhesives4i();
	prodAdhesives.Init(common);
*/
const productAdhesives4i = function(){
	const adhesiveInput = document.getElementById('adhesiveSelection');
	const selBaseTrim = document.getElementById('selectedColorID');

	let common, prodConfig;
	
	let init = (commonJS) => {
		common = commonJS;
		prodConfig = common.ProductConfig;
	},
	changed = () => {
		toggleAvailable();
		// Find the selected nib and ink color in the master list and set the id to the hidden input
		let selectedAdhesive = document.getElementById("adhOptions")[document.getElementById("adhOptions").selectedIndex].textContent;
		let selectedAdhSide = document.getElementById("adhSideOptions")[document.getElementById("adhSideOptions").selectedIndex].textContent;
		for (let i = 0; i < prodConfig.AdhSidesOptions.adhesive.length; i++) {
			if ((selectedAdhSide == prodConfig.AdhSidesOptions.adhesive[i].side && selectedAdhesive == prodConfig.AdhSidesOptions.adhesive[i].adh) ||
				(typeof selectedAdhSide == 'undefined' && selectedAdhesive == prodConfig.AdhSidesOptions.adhesive[i].adh) ||
				(selectedAdhSide == prodConfig.AdhSidesOptions.adhesive[i].side && typeof selectedAdhesive == 'undefined')) {
				adhesiveInput.value = prodConfig.AdhSidesOptions.adhesive[i].id;
				common.ValidateCustomizeSections();
				return;
			}
			common.ValidateCustomizeSections();
		}
	},
	toggleAvailable = () => {
		// Need to hide ink colors that are not available for the selected nib size
		let selectedAdh = document.getElementById("adhOptions")[document.getElementById("adhOptions").selectedIndex].textContent;

		// Don't bother unless there are nib options and there are ink options set
		if (selectedAdh != "" && prodConfig.AdhSidesOptions.adhesive.length > 0) {
			let adhSideOptions = document.getElementById('adhSideOptions').querySelectorAll('option');
			if (adhSideOptions.length > 0) {
				let selectedAdhesiveSides = [];

				// Populate the array with all of the possible ink colors for this nib
				for (let i = 0; i < prodConfig.AdhSidesOptions.adhesive.length; i++) {
					if (selectedAdh == prodConfig.AdhSidesOptions.adhesive[i].adh)
						selectedAdhesiveSides[selectedAdhesiveSides.length] = prodConfig.AdhSidesOptions.adhesive[i];
				}

				// Toggle the side options
				for (let i = 0; i < selectedAdhesiveSides.length; i++) {
					let sideFound = false;

					for (let j = 0; j < adhSideOptions.length; j++) {
						if (!sideFound && selectedAdhesiveSides[i].value == adhSideOptions[j].adh) 
							sideFound = true;
					}

					if (sideFound) {
						adhSideOptions[i].classList.remove("hide");
						adhSideOptions[i].removeAttribute("disabled");
					} else if (adhSideOptions[i].value != "") {
						adhSideOptions[i].classList.add("hide");
						adhSideOptions[i].setAttribute("disabled", "disabled");

						// If the option being hidden is selected, move the option to the first visible ink option
						if (adhSideOptions[i].value == document.getElementById("adhSideOptions")[document.getElementById("adhSideOptions").selectedIndex].text) {
							document.getElementById("adhSideOptions").selectedIndex = 0;
						}
					}
				}
			}
		}
	},
	getAttrID = () => { 
		let adhesiveAttributeID;

		if (document.getElementById("adhSideOptions").selectedIndex != -1)
			// example item: 3832-S
			adhesiveAttributeID = adhesiveInput.value;  // holds the real value, need to make sure a side was selected

		if ((adhesiveAttributeID == "" || adhesiveAttributeID == undefined) && document.getElementById("adhOptions").selectedIndex != -1) 
			// example item: 3832-M
			adhesiveAttributeID = adhesiveInput.value; // holds the real value, need to make sure an option was selected

		return adhesiveAttributeID;
	},
	getOptions = () => { 
		let baseTrim = selBaseTrim.value;
		if (baseTrim != undefined && baseTrim != "" && prodConfig.ID != 'undefined') {
			let data = {
				ProductID: prodConfig.ID,
				Color: baseTrim
			};	        			
			fetch('/productattribute/adhesive', { 
				method: 'POST', 
				body: JSON.stringify(data), 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			}).then(function(response){
				return  (response.ok) ? response.json() : []; // response.json() returns a promise
			}).then(function(response){
				document.getElementsByClassName("attributesLoadingImg")[0].classList.add("hide");
				prodConfig.AdhSidesOptions = response;
				if (response != null && response.adhesive && response.adhesive.length > 0) {
					prodConfig.AdhSidesOptions = response; // Set to the global object for use when the user is making selections
					let AdhesiveOptions = [],
					AdhSideOptions = [],
					AdhesiveOptionIDs = [],
					AdhesiveSideIDs = [],
					EditAdhesiveValue = '',
					EditAdhSideValue = '';

					for (let i = 0; i < response.adhesive.length; i++) {
						let optionFound = false;
						// Look to see if the option has already been added to the adhesive option array
						for (let j = 0; j < AdhesiveOptions.length; j++) {
							if (!optionFound && AdhesiveOptions[j] == response.adhesive[i].adh)
								optionFound = true;
						}
						// if the adhesive option was not found, add the option to the array for display
						if (!optionFound) {
							AdhesiveOptions[AdhesiveOptions.length] = response.adhesive[i].adh;
							AdhesiveOptionIDs[AdhesiveOptionIDs.length] = response.adhesive[i].id;
						} else {
							optionFound = false; // Reset the variable for the color check
						}
						// Look to see if the option has already been added to the side option array
						for (let k = 0; k < AdhSideOptions.length; k++) {
							if (!optionFound && AdhSideOptions[k] == response.adhesive[i].side) 
								optionFound = true;
						}
						// if the side option was not found, add the option to the array for display
						if (!optionFound) {
							AdhSideOptions[AdhSideOptions.length] = response.adhesive[i].side;
							AdhesiveSideIDs[AdhesiveSideIDs.length] = response.adhesive[i].id;
						}
						if (prodConfig.EditID > 0) {
							if (response.adhesive[i].id == adhesiveInput.value) {
								EditAdhSideValue = response.adhesive[i].side;
								EditAdhesiveValue = response.adhesive[i].adh;
							}
						}
					}

					// All adhesive and side options have been located, now display them
					if (AdhesiveOptions.length >= 1) {
						AdhesiveOptions.sort();

						if (document.getElementById("adhOptions")) {
							document.querySelector("#adhOptions").removeEventListener("change", changed); // Bind the handler to the new dropdowns
							for (let i = document.getElementById("adhOptions").options.length - 1; i >= 0; i--) {
								document.getElementById("adhOptions").remove(i);
							} // Remove any previously displayed options
						}
						let selectAdhOpt = '';
						if (AdhesiveOptions.length > 1) 
							selectAdhOpt += '<option id="defaultAdhOption" name="adhesive" value="">Select Adhesive Type...</option>';
						
						for (let x = 0; x < AdhesiveOptions.length; x++) {
						       let inputId = "adhesive" + x;
							selectAdhOpt += '<option id="' + inputId + '" name="adhesive" tabindex="0" value="' + AdhesiveOptions[x] + '"';
							// Set up the selected option, the first option by default if the item is not being edited
							if ((prodConfig.EditID > 0 && AdhesiveOptions[x] == EditAdhesiveValue)) {
								selectAdhOpt += ' selected="selected"';
							}
							
							selectAdhOpt += '>' + AdhesiveOptions[x] + '</option>';
						}
						document.getElementById("adhOptions").insertAdjacentHTML('afterbegin', selectAdhOpt);
						document.getElementById("adhOptions").classList.add("txtReq");
						if (prodConfig.EditID < 0 && AdhesiveOptions.length > 1) {
							document.getElementById("defaultAdhOption").setAttribute("selected", "selected");
						}

						if (AdhesiveOptions.length == 1) {
							document.getElementById("adhOptions").setAttribute("disabled", "disabled");
						} else {
							document.getElementById("adhOptions").disabled = false;
						}

						document.querySelector('.PD_Adhesive').classList.remove("hide");
						document.querySelector("#adhOptions").addEventListener("change", changed); // Bind the handler to the new dropdowns
					} else {
						document.querySelector('.PD_Adhesive').classList.add("hide");
					}

					if (AdhSideOptions.length >= 1) {
						AdhSideOptions.sort();

						if (document.getElementById("adhSideOptions")) {
							document.querySelector("#adhSideOptions").removeEventListener("change", changed); // Bind the handler to the new dropdowns
							for (let i = document.getElementById("adhSideOptions").options.length - 1; i >= 0; i--) {
								document.getElementById("adhSideOptions").remove(i);
							} // Remove any previously displayed options
						}
						let selectAdhSideOpt = '';
						if (AdhSideOptions.length > 1) {
							selectAdhSideOpt += '<option id="defaultAdhSideOption" name="adhSide" value="">Select Adhesive Side...</option>';
						}
						for (let i = 0; i < AdhSideOptions.length; i++) {
						       for (let j = 0; j < response.adhesive.length; j++) {
							       if (response.adhesive[j].side == AdhSideOptions[i]) {
									let adhesiveSide = "adhesiveSide" + i;
									if (selectAdhSideOpt.indexOf(adhesiveSide) == -1) {
										selectAdhSideOpt += '<option id="' + adhesiveSide + '" name="adhSide" tabindex="0"';// value="' + AdhSideOptions[i] + '"';
										// Set up the selected option, the first option by default if the item is not being edited
										if ((prodConfig.EditID > 0 && AdhSideOptions[i] == EditAdhSideValue)) {
											selectAdhSideOpt += ' selected="selected"';
										}
										if (response.adhesive[j].OutOfStock) {
										    selectAdhSideOpt += ' class="textRed" value="' + AdhSideOptions[i] + '"';
										} else {
										    selectAdhSideOpt += ' value="' + AdhSideOptions[i] + '"';
										}
										selectAdhSideOpt += '>' + AdhSideOptions[i] + '</option>';
									}
								}
							}
						}

						document.getElementById("adhSideOptions").insertAdjacentHTML('afterbegin', selectAdhSideOpt);
						if (!document.getElementsByClassName("hasNonSizingAttrBorder")[0]) {
							let addBorderHTML = '<div class="tinySpace"></div><div class="borderMdGray hasNonSizingAttrBorder"></div>';
							document.getElementsByClassName("attributesLoadingImg")[0].insertAdjacentHTML('beforebegin', addBorderHTML);
						}
						document.getElementById("adhSideOptions").classList.add("txtReq");
						if (prodConfig.EditID < 0 && document.getElementById("defaultAdhSideOption")) {
							document.getElementById("defaultAdhSideOption").setAttribute("selected", "selected");
						}

						if (AdhSideOptions.length == 1) {
							document.getElementById("adhSideOptions").setAttribute("disabled", "disabled");
						} else {
							document.getElementById('adhSideOptions').disabled = false;
						}
						document.querySelector('.PD_AdhesiveSide').classList.remove("hide");
						document.querySelector("#adhSideOptions").addEventListener("change", changed); // Bind the handler to the new dropdown
					} else {
						document.querySelector('.PD_AdhesiveSide').classList.add("hide");
					}

					if (prodConfig.AdhSidesOptions.adhesive.length > 0 && (AdhesiveOptions.length >= 1 || AdhSideOptions.length >= 1)) {
						// If there is a nib or color selection to be made, set the default selections into the hidden input
						changed();
						// Do the initial pass on the ink colors to make sure only the available ones are displayed
						toggleAvailable();
					}

				} else {
					let ad = document.querySelector('.PD_Adhesive');
					if (ad !== null) ad.classList.add("hide");

					let ads = document.querySelector('.PD_AdhesiveSide');
					if (ads !== null) ads.classList.add("hide");
				}
				if (common.IsGFeed) {
					if (document.getElementById("adhOptions").options.length > 1) 
						document.getElementById("adhOptions").selectedIndex = 1;
					else 
						document.getElementById("adhOptions").selectedIndex = 0;
						
					if (document.getElementById("adhSideOptions").options.length > 1) 
						document.getElementById("adhSideOptions").selectedIndex = 1;
					else
						document.getElementById("adhSideOptions").selectedIndex = 0;
				}
			}).catch(function(error){
				document.querySelector(".impConfigLoadingError").classList.remove("hide");
				document.querySelector('.PD_Adhesive').classList.add("hide");
				document.querySelector('.PD_AdhesiveSide').classList.add("hide");
			});
		}
	};
	return{
		Init: init,
		GetOptions: getOptions,
		GetAttrID: getAttrID
	};
};