const CheckIfFavorite4i = function(){		
	let init = () => {
		fetchCheckDetailsPage(document.querySelector(".faveLink"));
	},
	turnFavOn = (heartIcon) => {
		heartIcon.classList.remove("textLtGray");
		heartIcon.classList.add("textRed");
	},
	turnFavOff = (heartIcon) => {
		heartIcon.classList.remove("textRed");
		heartIcon.classList.add("textLtGray");
	},	
	fetchCheckDetailsPage = el =>{
		let productID = prodID;
        			
		fetch('/Productdata/IsItemInBoardAsync', { 
			method: 'POST', 
			body: JSON.stringify(productID), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.json() : []; // response.json() returns a promise
		}).then(function(response){
			if (response.success == true)
				turnFavOn(el);
			else
				turnFavOff(el);
		}).catch(function(error){
			// place error handling here
		});
	};
	return{
		Init: init
	};
};