const ExtraDetailsDialog4i = function(){
	const forms = new forms4i();
	const helpers = new formHelpers4i();
	const selBaseTrim = document.getElementById('selectedColorID');
	
	let itemDetailsDialog, common, prodConfig, localizedJS,
	init = (commonJS) =>{
		common = commonJS;
		prodConfig = common.ProductConfig;
		localizedJS = common.LocalizedJS;
		itemDetailsDialog = new Dialog4i();
		itemDetailsDialog.Init({
			triggerElements: [].slice.call(document.querySelectorAll(".setupTooltip")),
			dialogClass: "divItemDetails mediumDialog", 
			title: "Extra Charges", 
			content: helpers.LoadingIcon, 
			onOpen: open
		});
	},
	open = el =>{
		document.getElementById("loading").classList.remove("hide");
		if (selBaseTrim == undefined || selBaseTrim.value == "") selBaseTrim.value = document.querySelector('.selectedColorID').value;
		if (!document.getElementsByClassName("imprintConfiguration")[0]) {
			common.ConfigSectionLoadingExtraChg = true;
			return false;
		}
		getItemDetails(el);
	},
	getItemDetails = async (line) => {
		//let origFocus = line;
		//let chkdImprintLoc = 0;
		//let ExtrasTotal = 0.00;
		let qty = common.GetQty();
		common.GetSelectedImprintColors();
		common.GetSelectedImprintLocations();
		if (prodConfig.ListImprintLocationIDs.length == 0 || prodConfig.ListProductImprintLocationIDs.length == 0 || prodConfig.ListSelectedColors.length == 0 && parseInt(document.getElementsByClassName("extrasResult")[0].innerHTML) == 0) {
			forms.ClearValidation();
			if (prodConfig.AllImprintColors > 0) {
				if (common.BypassImpConfig) document.querySelector('.stepOneHdr').insertAdjacentHTML('afterend', helpers.ErrorMsgPanel);
			 	else document.querySelector('.imprintConfiguration').insertAdjacentHTML('beforebegin', helpers.ErrorMsgPanel);

				document.getElementById("ErrorBox").getElementsByTagName('li')[0].innerHTML = '<button id="imprintError" class="btnToHyp text16 underline textLeft">Please select at least one imprint location and one imprint ' + localizedJS.Color + '.</button>';
				document.getElementById("imprintError").focus();
				document.getElementById("imprintError").addEventListener("click", function (e) {
					e.preventDefault();
					document.querySelector(".selImprintLocation").focus();
				});
				return false;
			}
		}
		if (prodConfig.ListSelectedColors.length == 0 && !common.BypassImpConfig && parseInt(document.getElementsByClassName("extrasResult")[0].innerHTML) != 0) {
			prodConfig.ListSelectedColors.push(document.getElementsByClassName("imprintColorID")[0].value);
		}

		let includeOptExtras = "";
		//if (prodConfig.IsSingleShipItem && document.getElementById("SelectedOptionalExtras").value.indexOf(singleShipExtraId) == -1) {
			//includeOptExtras += ("*" + singleShipExtraId + ",");
		//}
		if (document.getElementById('SelectedOptionalExtras').value != "") {
			includeOptExtras += document.getElementById('SelectedOptionalExtras').value;
		}

		let baseTrimColor = selBaseTrim.value.split('/');
		let OrderSummary = {
			ProductID: prodConfig.ID,
			Quantity: qty,
			SSQuantity: prodConfig.IsSingleShipItem && document.getElementById("ssBoxesToSend")[document.getElementById("ssBoxesToSend").selectedIndex].value != "" ? document.getElementById("ssBoxesToSend")[document.getElementById("ssBoxesToSend").selectedIndex].value : 0,
			ImprintColors: prodConfig.ListSelectedColors,
			ImprintLocations: prodConfig.ListImprintLocationIDs,
			BaseColorID: baseTrimColor[0],
			TrimColorID: baseTrimColor[1],
			EditID: prodConfig.EditID,
			ProductImprintLocations: prodConfig.ListProductImprintLocationIDs,
			NibColorAttributeID: common.GetInkColorID(),
			AdhesiveAttributeID: common.GetAdhesiveID(),
			SelectedOptionalExtras: document.getElementById('SelectedOptionalExtras').value,
			IsReorder: document.getElementById("isReorder").value.toLowerCase() === 'true' // jag
		};
	
	
		let data = {
			Summary: OrderSummary,
			IncludeOptionalExtras: includeOptExtras
		};     	
		
		let responseHTML;
		try{
			const response = await fetch('/productmvc/itemextracharges', {
				method: 'POST', 
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, 'RequestVerificationToken': helpers.HeaderToken  }
			});
			if(!response.ok) throw new Error(`Error processing extra charges request: ${response.statusText}`);
			responseHTML = await response.text();
		}catch(error){
			console.error(error);
			showError();
		}
		
		if(responseHTML){
			document.getElementById("loading").classList.add("hide");
			if (responseHTML.success != false) {
				//insert table into dialog
				document.getElementById("popUpDialog").querySelector(".dialogContent").innerHTML = responseHTML;
				const prodNameHdr = document.getElementById("popUpDialog").querySelector("#extraChargesProdName");
				if(prodNameHdr) prodNameHdr.textContent = prodConfig.Name;
				if (!common.BypassImpConfig) common.GetExtrasAmount();
			} else { // error
				showError();
			}
		}
	},
	showError = () =>{
		/*if (!common.BypassImpConfig) {
			document.getElementsByClassName('extrasResultLine')[0].classList.add("hide");
		} else {
			document.getElementById('lblBaseExtras').parentNode.innerHTML = "";
		}*/
		document.getElementById("loading").classList.add("hide");
		let loadError = '<p role="status" class="text16 textSemiBold textDkRed">Sorry, an error has occurred. Please try again. You can also view item imprint charges in your cart.</p>';
		if (document.getElementById("popUpDialog")) {
		    document.getElementById("popUpDialog").querySelector(".dialogContent").innerHTML = loadError;
	       }
	};
	return{
		Init: init
	};
};