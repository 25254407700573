/*
	const prodInkColors = new productInkColors4i();
	prodInkColors.Init(common);
*/

const productInkColors4i = function(){
	const helpers = new formHelpers4i();
	const nibInkInput = document.getElementById('nibInkSelection');
	const selBaseTrim = document.getElementById('selectedColorID');

	let common, prodConfig;
	
	let init = (commonJS) => {
		common = commonJS;
		prodConfig = common.ProductConfig;
	},
	
	changed = () => {
		toggleAvailable();

		let selectedInk = document.getElementById("inkColorOptions")[document.getElementById("inkColorOptions").selectedIndex].textContent;
		let selectedNib = document.getElementById("NibOptions")[document.getElementById("NibOptions").selectedIndex].textContent;
		for (let i = 0; i < prodConfig.NibInkOptions.inknib.length; i++) {
			if ((selectedInk == prodConfig.NibInkOptions.inknib[i].ink && selectedNib == prodConfig.NibInkOptions.inknib[i].nib) ||
				(typeof selectedInk == 'undefined' && selectedNib == prodConfig.NibInkOptions.inknib[i].nib) ||
				(selectedInk == prodConfig.NibInkOptions.inknib[i].ink && typeof selectedNib == 'undefined')) {
				nibInkInput.value = prodConfig.NibInkOptions.inknib[i].id;
				//Update the hidden input that holds the value to properly match the nib type selected.
				document.querySelector(".colorName" + prodConfig.NibInkOptions.inknib[i].ink.replace(/\s/g, "").replace("/","").replace("/","").replace("(","").replace(")","")).value  = prodConfig.NibInkOptions.inknib[i].id;
				common.ValidateCustomizeSections();
				return;
			}
			common.ValidateCustomizeSections();
		}
	},
	getAttrID = () => { 
		let checkedNibColor = "";
		if (document.getElementById("inkColorOptions").selectedIndex != -1) {
			checkedNibColor = document.getElementById("inkColorOptions")[document.getElementById("inkColorOptions").selectedIndex].textContent;
		}
		let nibColorAttributeID;
		if (checkedNibColor != "" && document.getElementById("inkColorOptions").value != "") {
			nibColorAttributeID = document.querySelector(".colorName" + checkedNibColor.replace(/\s/g, "").replace("/","").replace("/","").replace("(","").replace(")","")).value;
		}

		return nibColorAttributeID;
	},
	toggleAvailable = () => {
		// Need to hide ink colors that are not available for the selected nib size
		let selectedNib = document.getElementById("NibOptions")[document.getElementById("NibOptions").selectedIndex].textContent;
		// Don't bother unless there are nib options and there are ink options set
		if (typeof selectedNib != 'undefined' && prodConfig.NibInkOptions.inknib.length > 0) {
			var inkOptions = document.getElementById('inkColorOptions').querySelectorAll('option');
			if (inkOptions.length > 0) {
				var nibInkColors = [];

				// Populate the array with all of the possible ink colors for this nib
				for (var i = 0; i < prodConfig.NibInkOptions.inknib.length; i++) {
					if (selectedNib == prodConfig.NibInkOptions.inknib[i].nib) {
						nibInkColors[nibInkColors.length] = prodConfig.NibInkOptions.inknib[i];
					}
				}

				// Toggle the ink colors
				for (var i = 0; i < inkOptions.length; i++) {
					var colorFound = false;

					for (var j = 0; j < nibInkColors.length; j++) {
						if (!colorFound && inkOptions[i].value == nibInkColors[j].ink) {
							colorFound = true;
						}
					}

					if (colorFound) {
						inkOptions[i].classList.remove("hide");
						inkOptions[i].removeAttribute("disabled");
					} else if (inkOptions[i].value != "") {
						inkOptions[i].classList.add("hide");
						inkOptions[i].setAttribute("disabled", "disabled");

						// If the option being hidden is selected, move the option to the first visible ink option
						if (inkOptions[i].value == document.getElementById("inkColorOptions")[document.getElementById("inkColorOptions").selectedIndex].text) {
							document.getElementById("inkColorOptions").selectedIndex = 0;
						}
					}
				}
			}
		}
	},
	getOptions = () => {
		let selectedColorID = selBaseTrim.value;
		if (selectedColorID != undefined && selectedColorID != "" && prodConfig.ID != 'undefined') {
			let data =  {
				ProductID: prodConfig.ID,
				Color: selectedColorID,
				EditID: prodConfig.EditID
			};
						
			fetch('/productattribute/nibink', { 
				method: 'POST', 
				body: JSON.stringify(data), 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			}).then(function(response){
				return  (response.ok) ? response.json() : []; 
			}).then(function(response){
				document.getElementsByClassName("attributesLoadingImg")[0].classList.add("hide");
				prodConfig.NibInkOptions = response;
				if (response != null && response.inknib && response.inknib.length > 0) {
					prodConfig.NibInkOptions = response; // Set to the global object for use when the user is making selections

					var NibOptions = [];
					var ColorOptions = [];
					var ProductColorAttributeIDs = [];
					var EditNibValue = '';
					var EditInkColorValue = '';

					for (var i = 0; i < response.inknib.length; i++) {
						var optionFound = false;
						// Look to see if the option has already been added to the nib option array
						for (var j = 0; j < NibOptions.length; j++) {
							if (!optionFound && NibOptions[j] == response.inknib[i].nib) {
								optionFound = true;
							}
						}
						// if the nib option was not found, add the option to the array for display
						if (!optionFound) {
							NibOptions[NibOptions.length] = response.inknib[i].nib;
						} else {
							optionFound = false; // Reset the variable for the color check
						}
						// Look to see if the option has already been added to the color option array
						for (var j = 0; j < ColorOptions.length; j++) {
							if (!optionFound && ColorOptions[j] == response.inknib[i].ink) {
								optionFound = true;
							}
						}
						// if the nib option was not found, add the option to the array for display
						if (!optionFound) {
							ColorOptions[ColorOptions.length] = response.inknib[i].ink;
							ProductColorAttributeIDs[ProductColorAttributeIDs.length] = response.inknib[i].id;
						}
						if (prodConfig.EditID > 0) {
							if (response.inknib[i].id == nibInkInput.value) {
								EditInkColorValue = response.inknib[i].ink;
								EditNibValue = response.inknib[i].nib;
							}
						}
					}
					// All color and nib options have been located, now display them
					if (NibOptions.length > 0) {
						if (document.getElementById('NibOptions')) {
							document.querySelector("#NibOptions").removeEventListener("change", changed); // Bind the handler to the new dropdown
							for (var i = document.getElementById("NibOptions").options.length - 1; i >= 0; i--) {
								document.getElementById("NibOptions").remove(i);
							}
						}
						var selectNib = '';
						if (NibOptions.length > 1) {
							selectNib += '<option id="defaultNibOption" name="nib" value="">Select Pen Tip Size...</option>';
						}
						for (var i = 0; i < NibOptions.length; i++) {
							var inputId = "nib" + i;
							selectNib += '<option id="' + inputId + '" name="nib" tabindex="0" value="' + NibOptions[i] + '"';
							// Set up the selected option, the first option by default if the item is not being edited
							if ((prodConfig.EditID > 0 && NibOptions[i] == EditNibValue)) {
								selectNib += ' selected="selected"';
							}
							selectNib += '>' + NibOptions[i] + '</option>';
						}
						document.getElementById("NibOptions").insertAdjacentHTML('afterbegin', selectNib);
						document.getElementById("NibOptions").classList.add("txtReq");
						if (prodConfig.EditID < 0 && NibOptions.length > 1) {
							document.getElementById("defaultNibOption").setAttribute("selected", "selected");
						}
						if (NibOptions.length == 1) {
							document.getElementById("NibOptions").setAttribute("disabled", "disabled");
						} else {
							document.getElementById("NibOptions").disabled = false;
						}
						document.querySelector('.PD_NibSize').classList.remove("hide");
						document.querySelector("#NibOptions").addEventListener("change", changed); // Bind the handler to the new dropdown
					} else {
						document.querySelector('.PD_NibSize').classList.add("hide");
					}
					if (ColorOptions.length > 0) {
						if (document.getElementById('inkColorOptions')) {
							document.querySelector("#inkColorOptions").removeEventListener("change", changed); // Bind the handler to the new dropdown
							for (var i = document.getElementById("inkColorOptions").options.length - 1; i >= 0; i--) {
								document.getElementById("inkColorOptions").remove(i);
							} // Remove any previously displayed options
						}
						var selectInk = '';
						if (ColorOptions.length > 1) {
							selectInk += '<option id="defaultInkColorOption" name="ink" value="">Select Writing Ink ' + common.ColorCapitalized + '...</option>';
						}
						for (i = 0; i < ColorOptions.length; i++) {
							for (var j = 0; j < response.inknib.length; j++) {
								if (response.inknib[j].ink == ColorOptions[i]) {
									var inkId = "ink" + i;
									if (selectInk.indexOf(inkId) == -1) {
										selectInk += '<option id="' + inkId + '"';// value="' + ColorOptions[i] + '"';
										// Set up the selected option, the first option by default if the item is not being edited
										if ((prodConfig.EditID > 0 && ColorOptions[i] == EditInkColorValue)) {
											selectInk += ' selected="selected"';
										}
										if (response.inknib[j].OutOfStock) {
											selectInk += ' class="textRed" value="' + ColorOptions[i] + '"';
										} else {
											selectInk += ' value="' + ColorOptions[i] + '"';
										}
										selectInk += '>' + ColorOptions[i] + '</option>';
									}
								}
							}							
						}
						document.getElementById("inkColorOptions").insertAdjacentHTML('afterbegin', selectInk);
						document.getElementById("inkColorOptions").classList.add("txtReq");
						if (prodConfig.EditID < 0 && ColorOptions.length > 1) {
							document.getElementById("defaultInkColorOption").setAttribute("selected", "selected");
						}
						if (ColorOptions.length == 1) {
							document.getElementById("inkColorOptions").setAttribute("disabled", "disabled");
						} else {
							document.getElementById("inkColorOptions").disabled = false;
						}
						var inputInk = "";
						for (var i = 0; i < ColorOptions.length; i++) {
							//Allows the hidden input to be assigned a class so the value can be updated via javascript
							var colorName = "colorName" + ColorOptions[i].replace(/\s/g, "").replace("/","").replace("/","").replace("(","").replace(")","");
							inputInk += '<input class="' + colorName + ' inkColorValue" type="hidden" tabindex="0" value="' + ProductColorAttributeIDs[i] + '" />';
						}
						document.getElementById("inkColorOptions").insertAdjacentHTML('afterend', inputInk);
						if (!document.getElementsByClassName("hasNonSizingAttrBorder")[0]) {
							var addBorderHTML = '<div class="tinySpace"></div><div class="borderMdGray hasNonSizingAttrBorder"></div>';
							document.getElementsByClassName("attributesLoadingImg")[0].insertAdjacentHTML('beforebegin', addBorderHTML);
						}
						document.querySelector('.PD_InkColor').classList.remove("hide");
						document.querySelector("#inkColorOptions").addEventListener("change", changed); // Bind the handler to the new dropdown
					} else {
						document.querySelector('.PD_InkColor').classList.add("hide");
					}
					if (prodConfig.NibInkOptions.inknib.length > 0 && (NibOptions.length >= 1 || ColorOptions.length >= 1)) {
						// If there is a nib or color selection to be made, set the default selections into the hidden input
						changed();
						// Do the initial pass on the ink colors to make sure only the available ones are displayed
						toggleAvailable();
					}
				} else {
					document.querySelector(".impConfigLoadingImg").classList.add("hide");
					//helpers.ShowErrorStatus(document.getElementById("imprintLocations"), "An error has occurred. Please refresh the page and try again.");
					document.querySelector('.PD_NibSize').classList.add("hide");
					document.querySelector('.PD_InkColor').classList.add("hide");
				}
				if (common.IsGFeed) {
					if (document.getElementById("NibOptions").options.length > 1) document.getElementById("NibOptions").selectedIndex = 1;
					else document.getElementById("NibOptions").selectedIndex = 0;
					if (document.getElementById("inkColorOptions").options.length > 1) document.getElementById("inkColorOptions").selectedIndex = 1;
					else document.getElementById("inkColorOptions").selectedIndex = 0;
				}
			}).catch(function(error){
				document.querySelector(".impConfigLoadingImg").classList.add("hide");
				document.querySelector(".impConfigLoadingError").classList.remove("hide");
				document.querySelector('.PD_NibSize').classList.add("hide");
				document.querySelector('.PD_InkColor').classList.add("hide");
			});
		}
	};
	return{
		Init: init,
		GetOptions: getOptions,
		GetAttrID: getAttrID
	};
};