/*
 const prodOptionalExtras = new optionalExtras4i();
 prodOptionalExtras.Init(common);
 */

const optionalExtras4i = function(){
	let common, prodConfig;
	
	let init = (commonJS) => {
		common = commonJS;
		prodConfig = common.ProductConfig;
	},
	getOptions = () => { 
		var qty = common.GetQty();
		if (!isNaN(qty)) {
			var data = {
				ProductID: prodConfig.ID,
				Quantity: qty
			};
				        			
			fetch('/productattribute/optionalextras', { 
				method: 'POST', 
				body: JSON.stringify(data), 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			}).then(function(response){
				return  (response.ok) ? response.json() : []; 
			}).then(function(response){
				document.getElementsByClassName("optExtrasLoadingImg")[0].classList.add("hide");
				prodConfig.OptionalExtras = response;

				if (response != null && response.extras.length > 0) {
					var ExtrasText = "<div class='extrasSection'>";

					for (var i = 0; i < response.extras.length; i++) {
						var checkit = "";
						if (prodConfig.EditID > 0) {
							if (document.querySelector('.SelectedOptionalExtras').value.indexOf('*' + response.extras[i].id + ',') != -1) {
								checkit = " checked = 'checked'";
							}
						}

						var unitprice = response.extras[i].unitprice;
						//var qty = document.querySelector(".selectedQty") ? document.querySelector(".selectedQty").innerHTML : "";
						var totalprice;
						if (!isNaN(unitprice) && qty != "") {
							totalprice = (unitprice * qty).toFixed(2);
						}
						ExtrasText += "<div class='imprintLocCheck'>";
						ExtrasText += "<input class='optionalExtra' type='checkbox' value='" + response.extras[i].id + "' id='opt" + response.extras[i].id + "' name='opt" + response.extras[i].id + "'" + checkit + " />";
						ExtrasText += "<label for='opt" + response.extras[i].id + "'>" + response.extras[i].displayname;
						if (!isNaN(totalprice)) {
							ExtrasText += "<span> (" + response.extras[i].displaydesc + " Add " + common.CurrencySymbol + common.FormatMoney(response.extras[i].unitprice) + " ea.)</span>";
						}
						ExtrasText += "</div>";
					}
					ExtrasText += "</div>";
					if (document.getElementById("addlFeatureSelect")) {
						document.getElementById("addlFeatureSelect").parentNode.removeChild(document.getElementById("addlFeatureSelect"));
					}
					if (document.getElementsByClassName("extrasSection").length == 0) {
						document.getElementsByClassName('addlFeatSelect')[0].insertAdjacentHTML('afterend', ExtrasText);
					}
					var optionalExtrasChks = [].slice.call(document.getElementsByClassName("optionalExtra"));
					optionalExtrasChks.forEach(function (elem) {
						elem.removeEventListener("change", addSelectedExtra);
						elem.addEventListener("change", addSelectedExtra);
					});
					document.getElementById('PD_OptionalExtra').classList.remove("hide");
				}
			}).catch(function(error){
				document.getElementById('PD_OptionalExtra').classList.add("hide");
			});
		}
	},
	addSelectedExtra = (e) => {
		let cb = e.target;
		if (document.getElementsByClassName("GFeedPanel1")[0]) {
			document.getElementsByClassName("GFeedPanel1")[0].parentNode.removeChild(document.getElementsByClassName("GFeedPanel1")[0]);
		}
		var currentselection = document.getElementById('SelectedOptionalExtras'); // this holds all the selected extras in '*11, *33,'
		if (cb.checked && currentselection.value.indexOf('*' + cb.value + ',') == -1) {
			currentselection.value = currentselection.value + '*' + cb.value + ',';
		} else if (currentselection.value.indexOf('*' + cb.value + ',') != -1) { // remove from if is there
			currentselection.value = currentselection.value.replace('*' + cb.value + ',', '');
		}
		document.getElementById('selectedOptionalExtraResult').classList.add("hide");
		common.GetSelectedImprintColors();
		document.getElementsByClassName("extrasRow")[0].classList.remove("hide");
		common.GetExtrasAmount(true); // add to the total
	};
	return{
		Init: init,
		GetOptions: getOptions
	};
};