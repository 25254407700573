const WaitToLoad4i = function () {
	const localizedJS = new localizedDetails();
	const prodScrollerJS = new prodScroller();
	const starRatingJS = new StarRating4i();
	const productID = prodID;
	const isApparelItem = document.querySelector('.isApparelItem').value == "True";
	const tabbedProdContainer = document.getElementById('tabbedProductScroller');
	const detailTabsDiv = document.getElementById('detailTabs');
	const productCatsDiv = document.getElementById('productCats');
	let options, observer, tabA11yStatus, tabCount;
	 
	let init = () => {
		starRatingJS.Init();
		observer = new IntersectionObserver(callBack, options);
		
		if (tabbedProdContainer) setUpObserve(tabbedProdContainer);
		if (detailTabsDiv) setUpObserve(detailTabsDiv);
		if (productCatsDiv) setUpObserve(productCatsDiv);
	},
	setUpObserve = container => {
		options = { root: container, threshold: 0.9 }; // 0.9 instead of 1 for safari fix
		observer.observe(container);
	},
 	callBack = (entries, observer) => {
		entries.forEach(function (entry) {
			if (entry.intersectionRatio > 0) {
				if (entry.target === tabbedProdContainer) getAllProdScroller();
				if (entry.target === detailTabsDiv) getDetailTabs(); 
				if (entry.target === productCatsDiv) getItemCategories();
			}
		});
	},
	showHideTabs = () => {
		if (isApparelItem) document.getElementById("fitRatingTS").classList.remove("hide");
		if (localizedJS.showCustomerImages) document.getElementById("customerImagesTS").classList.remove("hide");
		if (!localizedJS.showSuccessStories) document.getElementById("successStoriesTS").classList.add("hide");
	},
	getDetailTabs = async () => {
		observer.unobserve(detailTabsDiv);
		let page = 1;
		if (window.location.href.match(/\/page\/(\d*)/) != null) page = window.location.href.match(/\/page\/(\d*)/)[1];

		let data = {
			ProductID: productID,
			InfoType: parseInt(document.getElementById('InfoType').value),
			QID: document.getElementById('QuestionID').value,
			RFilter: document.getElementById('ReviewFilter').value,
			FitRatingFilter: document.getElementById('FitCommentFilter').value,
			Page: page
		};
		
		let responseHTML;
		try{
			const response = await fetch('/productmvc/GetDetailsTabAsync', {
				method: 'POST', 
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			//if(!response.ok) throw new Error(`Error processing request: ${response.statusText}`);
			responseHTML = await response.text();
		}catch(error){
			console.error(error);
			detailTabsDiv.innerHTML = "";
		}
		
		if(responseHTML){
			if(responseHTML.length > 0){
				tabA11yStatus = document.getElementById("detailTabStatus") ? document.getElementById("detailTabStatus") : "";
				detailTabsDiv.innerHTML = responseHTML;
				
				showHideTabs();
				window.addEventListener("resize", showHideTabs);
				
				const reviewStoryFitTabs = new tabs4i();
				reviewStoryFitTabs.init(detailTabsDiv);
				
				const customerReviews = new CustomerReviews4i();
				customerReviews.Init();
	
				if (isApparelItem) {
					const fitRatings = new FitRating4i();
					fitRatings.Init();
				}
				if (localizedJS.showCustomerImages) {
					const customerImages = new CustomerImages4i();
					customerImages.Init();
				}
				if (localizedJS.showSuccessStories){
					const successStories = new SuccessStories4i();
					successStories.Init();
				}
				starRatingJS.Init();
			}
			else{
				detailTabsDiv.innerHTML = "";
			}
		}
	},
	getItemCategories = async () => {
		observer.unobserve(productCatsDiv);
		if(productCatsDiv.classList.contains("hide")){  return false; }

		let responseHTML;
		try{
			const response = await fetch('/productmvc/GetItemCategoriesAsync', {
				method: 'POST', 
				body: JSON.stringify(productID),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			//if(!response.ok) throw new Error(`Error processing request: ${response.statusText}`);
			responseHTML = await response.text();
		}catch(error){
			console.error(error);
			productCatsDiv.innerHTML = "";
		}
		
		if(responseHTML){
			if(responseHTML.length > 0) productCatsDiv.innerHTML = responseHTML;
			else productCatsDiv.innerHTML = "";
		}
	},
	setUp5ItemScroller = (tabPanel, scroller) => {
		if (tabPanel) {
			prodScrollerJS.Init(scroller, 5, 4, 3);
			tabCount++;
		}
	},
	getAllProdScroller = async () => {
		observer.unobserve(tabbedProdContainer);
		if(tabbedProdContainer.classList.contains("hide")){  return false; }

		let responseHTML;
		try{
			const response = await fetch('/productmvc/GetTabbedScroller', {
				method: 'POST', 
				body: JSON.stringify(productID),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			if(!response.ok) throw new Error(`Error processing request: ${response.statusText}`);
			responseHTML = await response.text();
		}catch(error){
			console.error(error);
			tabbedProdContainer.innerHTML = '';
			tabbedProdContainer.classList.remove('relatedProductsPlaceholder');
		}
		
		if(responseHTML){
			if(responseHTML.length > 0){
				tabbedProdContainer.innerHTML = responseHTML;
				tabCount = 0;
		
				setUp5ItemScroller(document.getElementById("RelatedItemsPanel_1"), "RelatedItems_1"); 
				setUp5ItemScroller(document.getElementById("AlternateItemsPanel_1"), "AlternateItems_1");
				setUp5ItemScroller(document.getElementById("SimilarItemsPanel_1"), "SimilarItems_1"); 
				setUp5ItemScroller(document.getElementById("MoreUpscaleItemsPanel_1"), "MoreUpscaleItems_1"); 
				setUp5ItemScroller(document.getElementById("LowerpriceItemsPanel_1"), "LowerpriceItems_1"); 
				setUp5ItemScroller(document.getElementById("LowerMinQtyItemsPanel_1"), "LowerMinQtyItems_1"); 
				setUp5ItemScroller(document.getElementById("FasterItemsPanel_1"), "FasterItems_1"); 
				
				if (tabCount == 0) {
					tabbedProdContainer.innerHTML = '';
					tabbedProdContainer.classList.remove('relatedProductsPlaceholder');
				}else if(document.getElementById("allTabScroller") && tabCount == 1){
					starRatingJS.Init();
				}else if(document.getElementById("allTabScroller") && tabCount > 1){
					const allTabs = new tabs4i();
					allTabs.init(document.getElementById("allTabScroller"));
					allTabs.initTab(document.getElementById("allTabScroller").querySelector("button"));
					starRatingJS.Init();
				}
			}else{
				tabbedProdContainer.innerHTML = '';
				tabbedProdContainer.classList.remove('relatedProductsPlaceholder');
			}
		}
	};
	return{
		Init: init
	};
};