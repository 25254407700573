//ONLY IN US & CA
const CustomerImages4i = function () {
	let customerImgContainer, customerImgTab, customerImgProdID, custImgLoading, a11yStatus,

	init = () => {
		initVariables();
		
		customerImgContainer.removeEventListener("click", events);
		customerImgContainer.addEventListener("click", events);
	},
	initVariables = () => {
		customerImgContainer = document.getElementById("customerImagesTSTab");
		customerImgTab = document.getElementById("customerImagesTS");
		customerImgProdID = prodID;
		custImgLoading = customerImgContainer.querySelector('.loadingPage');
		a11yStatus = document.getElementById("detailTabStatus");
	},
	events = e => {
		if(e.target.classList.contains("imagespageselect")){
			e.preventDefault();
			getCustomerImagePage(e.target);
		}
	},
	updateStatus = () => {
		a11yStatus.textContent = "";
		setTimeout(() => {
			a11yStatus.textContent = "Updated Customer Image Results";
		}, 30);
	},
	getCustomerImagePage = el => {
		let vals = el.id.split('_'),
		page = vals[1],
		url = removeTrailingSlash("/productmvc/customerimages/" + customerImgProdID + "/" + page);
		
		custImgLoading.classList.remove('hide');

		fetch(url, {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			customerImgContainer.innerHTML = '';
			customerImgContainer.innerHTML = response;
			updateStatus();
			custImgLoading.classList.add('hide');
			init();
			customerImgTab.focus();
		}).catch(function (response) {
			custImgLoading.classList.add('hide');
		});
	},
	removeTrailingSlash = url => {
		// remove any trailing slash in URL to prevent 301 redirect to HTTP protocol which causes ASE to block request
		if (url.charAt(url.length - 1) === "/") url = url.substring(0, url.length - 1);
		return url;
	};

	return {
		Init: init
	};
};