const CustomerReviews4i = function () {
	let reviewsContent, reviewCatAlias, reviewReportSKU, reviewProdID, loadingIcon, reviewFilters, a11yStatus, reviewsOrderBy, reviewsPP, reviewOpts,

	init = () => {
		initVariables();
		addEvents();
	},
	initVariables = () => {
		reviewsContent = document.getElementById('reviewsTSTab');
		reviewCatAlias = document.getElementById('CatalogAlias').value;
		reviewReportSKU = document.getElementById('ReportSKU').value;
		reviewProdID = prodID;
		loadingIcon = reviewsContent.querySelector('.loadingPage');
		reviewFilters = document.getElementById("reviewFilters");
		a11yStatus = document.getElementById("detailTabStatus");
		reviewsOrderBy = document.getElementById('reviewsOrderBy') ? document.getElementById('reviewsOrderBy').value : "newest";
		reviewsPP = document.getElementById('reviewsPerPage') ? document.getElementById('reviewsPerPage').value : "8";
		reviewOpts = document.querySelector(".reviewOptions");
	},
	addEvents = () => {
		reviewsContent.removeEventListener("click", events);
		reviewsContent.addEventListener("click", events);
		
		let reviewOpts = [].slice.call(document.querySelectorAll('.reviewOptions'));
		reviewOpts.forEach((reviewOpt) => {
			reviewOpt.removeEventListener('change', fetchChangeReview);
			reviewOpt.addEventListener('change', fetchChangeReview);
		});
	},
	events = e => {
		let el = e.target;
		if(e.target.classList.contains("reviewspageselect")){
			e.preventDefault();
			fetchGetPage(el);
		}else if(e.target.classList.contains("reviewFilter")){ 
			e.preventDefault();
			fetchFilterChange(el);
		}else if(e.target.classList.contains("positiveReview")){ 
			e.preventDefault();
			fetchPositiveReview(el);
		}else if(e.target.classList.contains("negativeReview")){
			e.preventDefault();
			fetchNegativeReview(el);
		}else if(e.target.id == "readAllReviews"){
			e.preventDefault();
			fetchLoadAllReviews();
		}
	},
	removeReviewMsg = () => {
		if (document.querySelector('.reviewRemarkMsg')) document.querySelector('.reviewRemarkMsg').remove();
	},
	updateStatus = () => {
		a11yStatus.textContent = "";
		setTimeout(() => { a11yStatus.textContent = "Updated Review Results"; }, 30);
	},
	updateDropdownsInit = () => {
		reviewsOrderBy = document.getElementById('reviewsOrderBy') ? document.getElementById('reviewsOrderBy').value : "newest";
		reviewsPP = document.getElementById('reviewsPerPage') ? document.getElementById('reviewsPerPage').value : "8";
	},
	removeTrailingSlash = url => {
		// remove any trailing slash in URL to prevent 301 redirect to HTTP protocol which causes ASE to block request
		if (url.charAt(url.length - 1) === "/") url = url.substring(0, url.length - 1);
		return url;
	},	
	showNewReviews = (response, focusEl) => {
		if(loadingIcon) loadingIcon.classList.add('hide');
		reviewsContent.innerHTML = '';
		reviewsContent.innerHTML = response;
		updateStatus();
		init();
	},
	fetchPositiveReview = el => {
		let reviewID = el.parentNode.querySelector('.reviewID').value,
		data = { ReportSKU: reviewReportSKU, ReviewID: reviewID, PositiveReview: true, NegativeReview: false, CatalogAlias: reviewCatAlias };
		
		removeReviewMsg();
		
		fetch('/productmvc/reviewremark', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.json() : []; // response.json() returns a promise
		}).then(function (response) {
			if (response.success == "true") 
				el.nextElementSibling.insertAdjacentHTML('afterend', '<p class="textBlue textSemiBold reviewRemarkMsg paddingTop10" role="alert" aria-atomic="true">' + response.message + '</p>');
			else 
				el.nextElementSibling.insertAdjacentHTML('afterend', '<p class="textDkRed textSemiBold reviewRemarkMsg paddingTop10" role="alert" aria-atomic="true">' + response.message + '</p>');
		});
	},
	fetchNegativeReview = el => {
		let reviewID = el.parentNode.querySelector('.reviewID').value,
		data = { ReportSKU: reviewReportSKU, ReviewID: reviewID, PositiveReview: false, NegativeReview: true, CatalogAlias: reviewCatAlias };

		removeReviewMsg();

		fetch('/productmvc/reviewremark', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.json() : []; // response.json() returns a promise
		}).then(function (response) {
			if (response.success == "true")
				el.insertAdjacentHTML('afterend', '<p class="textBlue textSemiBold reviewRemarkMsg paddingTop10" role="alert" aria-atomic="true">' + response.message + '</p>');
			else
				el.insertAdjacentHTML('afterend', '<p class="textDkRed textSemiBold reviewRemarkMsg paddingTop10" role="alert" aria-atomic="true">' + response.message + '</p>');
		});
	},
	fetchFilterChange = el => {
		updateDropdownsInit();
		let filterID = el.id,
		vals = filterID.split('_'),
		filter = '';
		if (vals.length > 1) filter = vals[1]; 
		let url = removeTrailingSlash("/productmvc/reviews/" + reviewProdID + "/1/" + reviewsPP + "/" + reviewsOrderBy + "/" + filter);

		loadingIcon.classList.remove('hide');

		fetch(url, {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			showNewReviews(response);
			setTimeout(() => { 
				document.getElementById(filterID).focus(); 
			}, 100);
		});
	},
	fetchChangeReview = e => {
		updateDropdownsInit();
		let targetID = e.target.id,
		url = removeTrailingSlash("/productmvc/reviews/" + reviewProdID + "/1/" +reviewsPP + "/" + reviewsOrderBy + "/" + reviewFilters.value);

		loadingIcon.classList.remove('hide');
		
		fetch(url, {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			showNewReviews(response);
			setTimeout(() => { 
				document.getElementById(targetID).focus();
			}, 100);
		});
	},
	fetchGetPage = el => {
		updateDropdownsInit();
		let vals = el.id.split('_'),
		page = vals[1],
		url = removeTrailingSlash("/productmvc/reviews/" + reviewProdID + "/" + page + "/" + reviewsPP + "/" + reviewsOrderBy + "/" + reviewFilters.value);
		
		loadingIcon.classList.remove('hide');

		fetch(url, {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			showNewReviews(response);
			setTimeout(() => { 
				 document.getElementById('reviewsOrderBy').focus(); 
			}, 100);
		}).catch(function (response) {
			loadingIcon.classList.add('hide');
		});
	},
	fetchLoadAllReviews = () => {
		loadingIcon.classList.remove('hide');
		
		fetch("/productmvc/reviews/" + reviewProdID + "/1", {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			showNewReviews(response);
			setTimeout(() => { 
				 document.getElementById('reviewsOrderBy').focus(); 
			}, 100);
		});
	};

	return {
		Init: init
	};
};