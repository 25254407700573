//ONLY IN US & CA
const SuccessStories4i = function () {
	let storyContainer, storyProdID, storyLoading, a11yStatus, ssTabBtn,

	init = () => {
		initVariables();

		storyContainer.removeEventListener("click", events);
		storyContainer.addEventListener("click", events);
	},
	initVariables = () => {
		storyContainer = document.getElementById('successStoriesTSTab');
		storyProdID = prodID;
		storyLoading = storyContainer.querySelector('.loadingPage');
		a11yStatus = document.getElementById("detailTabStatus");
		ssTabBtn = document.getElementById("successStoriesTS");
	},
	events = e => {
		let el = e.target;
		if(e.target.classList.contains("storiespageselect")){
			e.preventDefault();
			getStoryPage(el);
		}
	},
	updateStatus = function(){
		a11yStatus.textContent = "";
		setTimeout(() => {
			a11yStatus.textContent = "Updated Success Story Results";
		}, 30);
	},
	getStoryPage = el => {
		let vals = el.id.split('_'),
		page = vals[1],
		url = removeTrailingSlash("/productmvc/successstories/" + storyProdID + "/" + page);
		
		storyLoading.classList.remove('hide');

		fetch(url, {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			storyLoading.classList.add('hide');
			storyContainer.innerHTML = '';
			storyContainer.innerHTML = response;
			updateStatus();
			init();
			ssTabBtn.focus();
		}).catch(function (response) {
			storyLoading.classList.add('hide');
		});
	},
	removeTrailingSlash = url => {
		// remove any trailing slash in URL to prevent 301 redirect to HTTP protocol which causes ASE to block request
		if (url.charAt(url.length - 1) === "/") url = url.substring(0, url.length - 1);
		return url;
	};

	return {
		Init: init
	};
};