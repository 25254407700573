/*
const addItemToCart = new addToCart4i();
addItemToCart.Send();
*/

var addToCart4i = function(commonJS){	
	const helpers = new formHelpers4i();
	const forms = new forms4i(); 
	const common = commonJS;
	const prodConfig = common.ProductConfig;
	const localizedJS = common.LocalizedJS;
	const colorSummary = document.getElementById('colorSummary');
	const locationSummary = document.querySelector('.imprint-location-summary');
	const qtySummary = document.getElementById('qtySummary');
	const colorSelected = document.getElementById('colorSelected');
	const selBaseTrim = document.getElementById('selectedColorID');
	const cartLoadingImgs = [].slice.call(document.querySelectorAll('.cartLoadingImg'));
	const quantityErrorHtml = '<div id="ErrorBox" class="qtyErrorMsg ErrorMsgPanel" role="alert" aria-atomic="true"><h3 id="ErrorTitle" class="validationHeading">Please correct the following errors and resubmit:</h3>' +
		'<ul id="ErrorMsgBox"><li><button id="quantityError" class="btnToHyp text16 underline textLeft">Minimum quantity for this item is ' + prodConfig.LowestQuantity + '.</button></li></ul></div>';
	
	//let cartUrl;
	let selectedColorID, ssQty, SSDoc, qty, restrictedPricing, selectedSizes;
	let selectedOptionalExtras = "";

	const send = async () => {
		common.GetSelectedImprintLocations();
		if (prodConfig.ListImprintLocationIDs.length == 0 || ( prodConfig.ListImprintLocationIDs.length > 0 && prodConfig.ListImprintLocationIDs.includes(-1))) {
			showErrors();
			return false;
		}		
		common.GetSelectedImprintColors();
		forms.ClearValidation();
		getExtras();
		
		if (document.getElementById('SelectedOptionalExtras').value != "") {
			selectedOptionalExtras += document.getElementById('SelectedOptionalExtras').value;
		}
		
		selectedSizes = getSizes();
		selectedColorID = selBaseTrim.value;		
		setSingleShipQty();
		qty = common.GetQty();
		qtySummary.innerHTML = qty;
		colorSummary.innerHTML = colorSelected.value;
		
		const sizeGrid = document.getElementById('PD_SizeGrid');
		if (prodConfig.IsApparelItem && sizeGrid.innerHTML.length > 0 && !prodConfig.IsSingleShipItem && !common.IsMobile) common.CalcPrice(); 
		
		validateProductColor();
		validateQuantity();
		setSummaryImprintColors();
		validateAdhesiveSide();
		validateAdhesive();
		validateNibType();
		validateInkColor();
		processSingleShip();
		showLoading();
		removeErrors();
		
		if(prodConfig.HidePricing){
			restrictedPricing = new RestrictedPricing4i();
			restrictedPricing.Init(common);
		}

		//var selectedOptionalExtras = "";
		/*if (document.getElementById('SelectedOptionalExtras').value != "") {
			selectedOptionalExtras += document.getElementById('SelectedOptionalExtras').value;
		}*/
		
		const OrderSummary = getOrderObject();		
		let responseJSON;
		try{
			const response = await fetch('/productmvc/addtocart', {
				method: 'POST', 
				body: JSON.stringify(OrderSummary),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, 'RequestVerificationToken': helpers.HeaderToken  }
			});
			if(!response.ok) throw new Error(`Error processing add product to cart request: ${response.statusText}`);
			responseJSON = await response.json();
		}catch(error){
			console.error(error);
			hideLoading();
			showErrors();
			common.Processing = false;
		}
		
		if(responseJSON){
			if (responseJSON.success == "true") {
				omniLogCartAdd(responseJSON.newCart == undefined ? true : responseJSON.newCart);
				//showItemSummary();
				//cartUrl = responseJSON.redirectUrl;
				if (prodConfig.HidePricing) {
					if (prodConfig.EditID < 0) openRestrictedPricingDialog(responseJSON);
					else logCartAddGTM(responseJSON.redirectUrl);
				} else {
					logCartAddGTM(responseJSON.redirectUrl);
				}
			} else {
				hideLoading();
				showErrors();
			}
			common.Processing = false;
		}else{
			hideLoading();
			showErrors();
			common.Processing = false;
		}
	};
	
	//todo move this to common?
	const getOrderObject = () => {
		const ssDropdown = document.getElementById("ssBoxesToSend");
		const baseTrimColor = selBaseTrim.value.split('/');

		const summary = {
			ProductID: prodConfig.ID,
			Quantity: qty,
			SSQuantity: prodConfig.IsSingleShipItem && ssDropdown && ssDropdown[ssDropdown.selectedIndex].value != "" ? ssDropdown[ssDropdown.selectedIndex].value : 0,
			ImprintColors: prodConfig.ListSelectedColors,
			ImprintLocations: prodConfig.ListImprintLocationIDs,
			SelectedSizes: selectedSizes,
			BaseColorID: baseTrimColor[0],
			TrimColorID: baseTrimColor[1],
			EditID: prodConfig.EditID,
			ProductImprintLocations: prodConfig.ListProductImprintLocationIDs,
			NibColorAttributeID: common.GetInkColorID(), 
			AdhesiveAttributeID: common.GetAdhesiveID(),
			SelectedOptionalExtras: selectedOptionalExtras,
			IsReorder: document.getElementById("isReorder").value.toLowerCase() === 'true',  // jag
			SSDoc: SSDoc
		};
		return summary;
	};
	
	const getSizes = () => {
		let sizeHash = "";
		if (prodConfig.IsApparelItem) {
			let sizeOptions = [].slice.call(document.querySelectorAll(".sizeOptionQty"));
			sizeOptions.forEach((el) => {
				let sizeQty = el.getElementsByClassName('txtApparelQty')[0].value;
				if (sizeQty != '' && sizeQty != undefined && sizeQty != '0') {
					let saPID = el.getElementsByClassName('sizeAttrProdID')[0].value,
					saID = el.getElementsByClassName('sizeAttrID')[0].value,
					qty = el.getElementsByClassName('txtApparelQty')[0].value;
					
					sizeHash += saPID + '-' + saID + '-' + qty + '|';
				}
			});
		}
		return sizeHash;
	};

	const openRestrictedPricingDialog = (response) => {
		prodConfig.EditID = response.cartItemID;
		logCartAddGTM();

		//cart MS uses Sequence instead of CartItemID
		// removing as of 10/29/19 as restricted items weren't being deleted from cart
		// readded 1/17/2020 as the go to cart is broken without this
		// editId needs to be an int or it fails modelstate, cartItemId is a guid
		if (response.sequence > 0) prodConfig.EditID = response.sequence;
		hideLoading();
		restrictedPricing.Open(response);
	};
	
	const showLoading = () => {
		cartLoadingImgs.forEach(function (el) {
			el.classList.remove("hide");
		});
	};
	
	const hideLoading = () => {
		cartLoadingImgs.forEach(function (el) {
			el.classList.add("hide");
		});
	};
	
	const removeErrors = () => {
		const cartErrors = [].slice.call(document.querySelectorAll('.cartError'));
		cartErrors.forEach(function (el) {
			el.innerHTML = '';
		});
	};
	
	const showErrors = () => {
		const cartErrors = [].slice.call(document.querySelectorAll('.cartError'));
		cartErrors.forEach(function (el) {
			el.innerHTML = '<div aria-live="polite" role="status">There was an error adding item to Cart. Please try again!</div>';
		});
	};
	
	//Item Summary will be disabled for now.
	//This might be added back in the future. JWW 10/24
	/*const showItemSummary = () => {
		$('.PD_Step_2').fadeOut('slow', function () {
		    $('.PD_Summary').fadeIn('100', function () {
		        $('.PD_Summary').removeClass('hide');
		        ScrollToElement('.PD_Summary', 500);
		    });
		});
	};*/
	
	const setSingleShipQty = () => {
		const singleShipDropdown = document.getElementById("ssBoxesToSend");
		ssQty = prodConfig.IsSingleShipItem ? singleShipDropdown[singleShipDropdown.selectedIndex].value : 0;
	};
	
	const validateProductColor = () => {
		if (selectedColorID == undefined || selectedColorID == "") {
			let colorError, firstError;
			if (prodConfig.RecolorAvailable) {
				const productColorScroller = document.getElementById('productColorScroller');
				productColorScroller.insertAdjacentHTML('beforebegin', helpers.ErrorMsgPanel);
				colorError = `<button id="colorScrollerError" class="btnToHyp text16 underline textLeft">Please select a product ${localizedJS.Color}.</button>`;
				document.querySelector(".ErrorMsgBox").querySelector('li').innerHTML = colorError;
				
				firstError = document.getElementById("colorScrollerError");
				firstError.focus();
				firstError.addEventListener("click", function (e) {
					e.preventDefault();
					productColorScroller.querySelector('.imgBox').focus();
				});
			} else {
				const productColorList = document.querySelector('.PD_ColorList');
				productColorList.insertAdjacentHTML('beforebegin', helpers.ErrorMsgPanel);
				colorError = `<button id="colorListError" class="btnToHyp text16 underline textLeft">Please select a product ${localizedJS.Color}.</button>`;
				document.querySelector(".ErrorMsgBox").getElementsByTagName('li')[0].innerHTML = colorError;
				
				firstError = document.getElementById("colorListError");
				firstError.focus();
				firstError.addEventListener("click", function (e) {
					e.preventDefault();
					document.getElementById('listView').querySelector('.selectedImgDesc').focus();
				});
			}
			return false;
		}
	};
	
	const validateQuantity = () => {
		const tooLowQuantity = (qty < prodConfig.LowestQuantity) ? true : false;
		const sizeGrid = document.getElementById('PD_SizeGrid');
		if (qty == isNaN || qty == undefined || qty == '0' || !common.IsNumeric(qty) || tooLowQuantity) {
			if (document.querySelector(".qtyErrorMsg")) document.querySelector(".qtyErrorMsg").remove();
			if (prodConfig.IsApparelItem && sizeGrid) showApparelError();
			else showGlideError();
			return false;
		}
	};
	
	const showApparelError = () => {
		const sizeGrid = document.getElementById('PD_SizeGrid');
		sizeGrid.insertAdjacentHTML('beforebegin', quantityErrorHtml);
		const apparelInputs = document.getElementsByClassName("txtApparelQty");
		for (let y = 0; y < apparelInputs.length; y++) {
			if (apparelInputs[y].value != '') {
				let input = apparelInputs[y];
				input.classList.add("input-validation-error");
				input.setAttribute('aria-invalid', 'true');
				const firstError = document.getElementById("quantityError");
				firstError.focus();
				firstError.addEventListener("click", function (e) {
					e.preventDefault();
					apparelInputs[0].focus();
				});
			}
		}
	};
	
	const showGlideError = () => {
		const glidePanel = document.querySelector('.glidePanel');
		const qtyInput = document.getElementById('txtQty');
		glidePanel.insertAdjacentHTML('afterend', quantityErrorHtml);
		qtyInput.classList.add("input-validation-error");
		qtyInput.setAttribute('aria-invalid', 'true');
		const firstError = document.getElementById("quantityError");
		firstError.focus();
		firstError.addEventListener("click", function (e) {
			e.preventDefault();
			qtyInput.focus();
		});
	};
	
	const setSummaryImprintColors = () => {
		var newContent = "";
		let i;
		if (prodConfig.ListSelectedImprintLocationName.length > 0 && prodConfig.ListSelectedColorsRGB.length > 0 && prodConfig.AllImprintColors > 0) {
			for (i = 0; i < prodConfig.ListSelectedImprintLocationName.length; i++) {
				newContent += 
					`<div class='clear tinySpace'></div>
					<h5 class='textSemiBold'>Imprint Location</h5>
					<p>${prodConfig.ListSelectedImprintLocationName[i]}</p>
					<div class='clear tinySpace'></div>
					<h5 class='textSemiBold'>Imprint ${localizedJS.ColorCap}(s)</h5>
					<div class='displayFlex'>
				`;
				for (var j = 0; j < prodConfig.ListSelectedColorsRGB[i].length; j++) {
					if (prodConfig.ListSelectedColorsRGB[i][j] != "") {
						newContent += 
							`<div class='imprintColorSummary marginTop5 marginBtm20 marginRight5 borderDkGray' style='background-color: ${prodConfig.ListSelectedColorsRGB[i][j]}'></div>
						`;
					}
				}
				newContent += `</div>`;
			}
		} else if (prodConfig.AllImprintColors == 0) {
			for (i = 0; i < prodConfig.ListSelectedImprintLocationName.length; i++) {
				let locationID = "impLocation_" + i;
				newContent += errorHTML("", "Imprint Location", locationID, prodConfig.ListSelectedImprintLocationName[i]);
			}
		}
		const newContentDiv = document.createElement('div');
		newContentDiv.innerHTML = newContent;
		locationSummary.insertBefore(newContentDiv, locationSummary.firstChild);
	};
	
	const errorHTML = (errorClass, errorName, errorID, error) => {
		//todo remove float
		const html = 
			`<div class="displayFlex tinySpace">
				<div class="${errorClass} c-sm-quarter-2 c-xs-full">
					<p class="textSemiBold">${errorName}</p>
				</div>
				<div class="c-sm-quarter-2 c-xs-full">
					<p class="floatRightOnMedium" id="${errorID}">${error}</p>
					<div class="clear"></div>
				</div>
			</div>
		`;
		return html;
	};
	
	const validateAdhesiveSide = () => {
		if (document.querySelector(".PD_adhSideOptions")) document.querySelector(".PD_adhSideOptions").parentNode.remove();
		const adhesiveSideOptionsDropdown = document.getElementById("adhSideOptions");
		if (adhesiveSideOptionsDropdown.selectedIndex != -1) {
			const adhesiveSideSelected = adhesiveSideOptionsDropdown[adhesiveSideOptionsDropdown.selectedIndex].textContent;
			const adhesiveSideError = errorHTML("PD_adhSideOptions", "Adhesive Side", "adhesiveSideSummary", adhesiveSideSelected);
			colorSummary.parentNode.insertAdjacentHTML('afterend', adhesiveSideError);
		}
	};
	
	const validateAdhesive = () => {
		if (document.querySelector(".PD_adhesiveOptions")) document.querySelector(".PD_adhesiveOptions").parentNode.remove();
		const adhesiveOptionsDropdown = document.getElementById('adhOptions');
		if (adhesiveOptionsDropdown.selectedIndex != -1) {
			const adhesiveTypeSelected = adhesiveOptionsDropdown[adhesiveOptionsDropdown.selectedIndex].textContent;
			const adhesiveSideDropdown = document.getElementById("adhSideOptions");
			const adhesiveSideSelected = adhesiveSideDropdown[adhesiveSideDropdown.selectedIndex].textContent;
			const errorInfo = adhesiveTypeSelected + ": " + adhesiveSideSelected;
			const adhesiveError = errorHTML("PD_adhesiveOptions", "Adhesive Type", "adhesiveTypeSummary", errorInfo);
			colorSummary.parentNode.insertAdjacentHTML('afterend', adhesiveError);
		}
	};
	
	const validateNibType = () => {
		if (document.querySelector(".PD_NibTypeSummary")) document.querySelector(".PD_NibTypeSummary").parentNode.remove();
		const nibOptionsDropdown = document.getElementById("NibOptions");
		if (nibOptionsDropdown.selectedIndex != -1) {
			const nibTypeSelected = nibOptionsDropdown[nibOptionsDropdown.selectedIndex].textContent;
			const nibError = errorHTML("PD_NibTypeSummary", "Tip Size", "nibTypeSummary", nibTypeSelected);
			colorSummary.parentNode.insertAdjacentHTML('afterend', nibError);
		}
	};
	
	const validateInkColor = () => {
		if (document.querySelector(".PD_inkColorSummary")) document.querySelector(".PD_inkColorSummary").parentNode.remove();
		const inkOptionsDropdown = document.getElementById("inkColorOptions");
		if (inkOptionsDropdown.selectedIndex != -1) {
			const inkColorSelected = inkOptionsDropdown[inkOptionsDropdown.selectedIndex].textContent;
			const errorTitle = "Writing Ink " + localizedJS.ColorCap;
			const inkError = errorHTML("PD_inkColorSummary", errorTitle, "inkColorSummary", inkColorSelected);
			colorSummary.parentNode.insertAdjacentHTML('afterend', inkError);
		}
	};
	
	const getExtras = async (triggerElement) => {
		//var ExtrasTotal = 0.00;
		qty = common.GetQty();
		if (!isNaN(qty)) {
			validateItem();
			const OrderSummary = getOrderObject();
			let responseHTML;
			try{
				const response = await fetch('/productmvc/calculateextras', {
					method: 'POST', 
					body: JSON.stringify(OrderSummary),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, 'RequestVerificationToken': helpers.HeaderToken  }
				});
				if(!response.ok) throw new Error(`Error processing get extras request: ${response.statusText}`);
				responseHTML = await response.text();
			}catch(error){
				console.error(error);
				if (!common.IsGFeed) common.ExtraChargesLoadError();
			}
			
			if(responseHTML){
				const setUpSummary = document.getElementById('setupChrgSummary');
				if (responseHTML.indexOf('extrasError') < 0) setUpSummary.innerHTML = responseHTML;
				else setUpSummary.innerHTML = '<p class="textSemiBold" role="alert" aria-atomic="true">Add To Cart To View Any Imprint Charges</p>';
			}
		}
	};
	
	const validateItem = () => {
		common.GetSelectedImprintLocations();
		common.GetSelectedImprintColors();
		
		if (prodConfig.ListImprintLocationIDs.length == 0 || prodConfig.ListProductImprintLocationIDs.length == 0 || prodConfig.ListSelectedColors.length == 0) {
			forms.ClearValidation();
			if (prodConfig.AllImprintColors > 0) {
				if (common.BypassImpConfig) {
					const stepOne = document.querySelector('.stepOneHdr');
					stepOne.insertAdjacentHTML('afterend', helpers.ErrorMsgPanel);
				} else {
					const imprint = document.querySelector('.imprintConfiguration');
					imprint.insertAdjacentHTML('beforebegin', helpers.ErrorMsgPanel);
				}
				document.getElementsByClassName("ErrorMsgBox")[0].getElementsByTagName('li')[0].innerHTML = '<button id="imprintError" class="btnToHyp text16 underline textLeft">Please select at least one imprint location and one imprint ' + localizedJS.Color + '.</button>';
				const firstError = document.getElementById("imprintError");
				firstError.focus();
				firstError.addEventListener("click", function (e) {
					e.preventDefault();
					document.querySelector(".selImprintLocation").focus();
				});
				return false;
			}
		}
	};
	
	const logCartAddGTM = (url) => {
		if (typeof dataLayer !== 'undefined' && window.ga) {
			// Measure adding a product to a shopping cart by using an 'add' actionFieldObject
			// and a list of productFieldObjects.
			dataLayer.push({
				'event': 'addToCart',
				'ecommerce': {
					'currencyCode': document.getElementById('gtmCurrencyCode').value,
					'add': {
						'products': [{
							'name': document.getElementById('ProductName').value.replace("'", ''),
							'id': document.getElementById('CatalogAlias').value,
							'price': (document.querySelector(".glidePanel") ? document.getElementById('lblPrice').value : document.getElementById('lblPrice').innerHTML).replace(common.CurrencySymbol, ''),
							'brand': document.getElementById('gtmBrand').value,
							'category': document.getElementById('gtmCategory').value,
							'variant': document.getElementById('colorSelected').value,
							'quantity': common.GetQty()
						}]
					}
				},
				'eventCallback': function () {
					if (url) window.location.href = url;
				}
			});
		} else {
			if (url) window.location.href = url;
		}
	};
	
	const omniLogCartAdd = (newCart) => {
		if (typeof s != "undefined") {
			var events = (newCart) ? 'scOpen, scAdd' : 'scAdd';
			var si = s_gi(s.account);
			si.linkTrackVars = 'products,events';
			si.linkTrackEvents = events;
			si.events = events;
			si.products = s.products;
			si.tl(true, 'o', 'Add To Cart');
		
			//s.events = (newCart) ? 'scOpen, scAdd' : 'scAdd';
			/************* DO NOT ALTER ANYTHING BELOW THIS LINE ! **************/
			//var s_code = s.t();
			//if (s_code) document.write(s_code);
		}
	};
	
	/* all single ship below this */
	const processSingleShip = () => {
		const selectedMsg = getSSMsg();
		const selectedInsertSize = getSSSize();
		const selectedBoxAndMsg = getSSBox();
		const linksToUploadedFiles = getSSFiles();
		const shipRemainingToMe = qty - ssQty != 0;
		const toMeAddy1 = document.getElementById("shipToMeAddr1");
		const toMeAddy2 = document.getElementById("shipToMeAddr2");
		const toMeAddy3 = document.getElementById("shipToMeAddr3");
		const toMeCity = document.getElementById("shipToMeTown");
		const toMeCompany = document.getElementById("shipToMeCoName");
		const toMeFirstName = document.getElementById("shipToMeFirstName");
		const toMeLastName = document.getElementById("shipToMeLastName");
		const toMeZip = document.getElementById("shipToMeZip"); 
		const toMeState = document.getElementById("shipToMeState");
		const flyerCheckbox = document.getElementById("noFlyer");
		const customMessage = document.getElementById("txtCustomMessage");
		const addLogo = document.getElementById("ShippingList.CustomMessage.AddLogoToMessage");
		
		SSDoc = !prodConfig.IsSingleShipItem ? null : {
			type: "",
			IsWebOrder: true,
			CustomMessage: {
				InsertSizes: null,
				SelectedInsertSize: selectedInsertSize ? selectedInsertSize.value.toString() : "0",
				MessageTypes: null,
				SelectedMsgType: selectedMsg == null && flyerCheckbox.checked ? "3" : selectedMsg == null ? "0" : selectedMsg.value,
				Message: customMessage.value,
				AddLogoToMessage: addLogo.value,
				LinksToUploadedFiles: linksToUploadedFiles,
				UseUploadedFile: selectedMsg != null && selectedMsg.value == "2" ? true : false,
			},
			SingleShipQuantity: ssQty,
			ShipRemainingToMe: shipRemainingToMe,
			ShipRemainingInfo: {
				ShipToMeAddr: {
					Address1: !shipRemainingToMe ? "" : toMeAddy1.innerHTML,
					Address2: !shipRemainingToMe ? "" : toMeAddy2.innerHTML,
					Address3: !shipRemainingToMe ? "" : toMeAddy3 ? toMeAddy3.innerHTML : "",
					City: !shipRemainingToMe ? "" : toMeCity.innerHTML,
					CompanyName: !shipRemainingToMe ? "" : toMeCompany.innerHTML,
					FirstName: !shipRemainingToMe ? "" : toMeFirstName.innerHTML,
					LastName: !shipRemainingToMe ? "" : toMeLastName.innerHTML,
					PostCode: !shipRemainingToMe ? "" : toMeZip.innerHTML,
					State: !shipRemainingToMe ? "" : toMeState.innerHTML,
				},
				IncludeBoxAndMsg: selectedBoxAndMsg ? selectedBoxAndMsg.value : null,
			},
			DocumentNotes: ""
		};
	};
	
	const getSSMsg = () => {
		const messageTypes = [].slice.call(document.querySelectorAll('input[name^="ShippingList.CustomMessage.SelectedMsgType"]'));
		let msg;
		messageTypes.forEach(function (el) {
			if (el.checked) msg = el;
		});
		return msg;
	};
	
	const getSSSize = () => {
		const insertSizes = [].slice.call(document.querySelectorAll('input[name^="ShippingList.CustomMessage.SelectedInsertSize"]'));
		let sizeInsert;
		insertSizes.forEach(function (el) {
			if (el.checked) sizeInsert = el;
		});
		return sizeInsert;
	};
	
	const getSSBox = () => {
		const boxAndMsgShipToMe = [].slice.call(document.querySelectorAll('input[name^="SSModel.ShippingList.ShipRemainingInfo.IncludeBoxAndMsg"]'));
		let msg;
		boxAndMsgShipToMe.forEach(function (el) {
			if (el.checked) msg = el;
		});
		return msg;
	};
	
	const getSSFiles = () =>{
		let files = [];
		const progressBoxes = [].slice.call(document.querySelectorAll('.progressBox'));
		progressBoxes.forEach(function (el) {
			if (document.getElementsByClassName("linksToUploadedFiles").length > 0) {
				let BlobURL = el.querySelector(".blobUrl").value;
				let CustomerFileName = el.querySelector(".customerFileName").value;
				let FileName = el.querySelector(".fileName").value;
				let LinksToUploadedFiles = {
					BlobURL: BlobURL,
					CustomerFileName: CustomerFileName,
					FileName: FileName,
				};
				files.push(LinksToUploadedFiles);
			}
		});
		
		return files;
	};
	
	return{
		Send: send
	};
};