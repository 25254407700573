const FitRating4i = function () {
	let filterSection, fitRatingContainer, fitComments, fitRatingProdID, fitLoading, a11yStatus, fitTab,

	init = () => {
		initVariables();
		
		fitRatingContainer.removeEventListener("click", events);
		fitRatingContainer.addEventListener("click", events);
	},
	initVariables = () => {
		filterSection = document.getElementById('fitratingfilter');
		fitRatingContainer = document.getElementById('fitRatingTSTab');
		fitComments = document.getElementById('customerfitcomments');
		fitRatingProdID = prodID;
		fitLoading = fitRatingContainer.querySelector(".loadingPage") ?  fitRatingContainer.querySelector(".loadingPage") : "";
		a11yStatus = document.getElementById("detailTabStatus");
		fitTab = document.getElementById("fitRatingTS");
	},
	events = e => {
		let el = e.target;
		if(e.target.classList.contains("fitratingpageselect")){
			e.preventDefault();
			fetchFitRatingPage(el);
		}else if(e.target.classList.contains("filterTag")){
			e.preventDefault();
			fetchFitRatingChange(el);
		}
	},
	updateStatus = () => {
		a11yStatus.textContent = "";
		setTimeout(() => {
			a11yStatus.textContent = "Updated Fit Rating Results";
		}, 30);
	},
	showNewFitRatings = response => {
		fitComments.innerHTML = '';
		fitComments.innerHTML = response;
		if (fitLoading !== "") fitLoading.classList.add("hide");
		updateStatus();
		init();
		fitTab.focus();
	},
	fetchFitRatingChange = el => {
		let vals = el.id.split('_'),
		filter = '';
		if (vals.length > 1) {
			filter = vals[1];
			filterSection.classList.remove('hide');
		} else {
			filterSection.classList.add('hide');
		}
		if (fitLoading !== "") fitLoading.classList.remove("hide");

		let url = removeTrailingSlash("/productmvc/customerfitrating/" + fitRatingProdID + "/1/" + filter);
		fetch(url, {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			showNewFitRatings(response);
		});
	},
	fetchFitRatingPage = el => {
		let vals = el.id.split('_'),
		page = vals[1];
		
		if (fitLoading !== "") fitLoading.classList.remove("hide");		
		let url = removeTrailingSlash("/productmvc/customerfitrating/" + fitRatingProdID + "/" + page + "/" + document.getElementById('fitRatingFilter').value);
		
		fetch(url, {
			method: 'GET',
			headers: { 'X-Requested-With': 'fetch' }
		}).then(function (response) {
			return (response.ok) ? response.text() : []; // response.text() returns a promise
		}).then(function (response) {
			showNewFitRatings(response);
		});
	},
	removeTrailingSlash = url => {
		// remove any trailing slash in URL to prevent 301 redirect to HTTP protocol which causes ASE to block request
		if (url.charAt(url.length - 1) === "/") url = url.substring(0, url.length - 1);
		return url;
	};

	return {
		Init: init
	};
};