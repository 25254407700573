const mainImageScroller4i = function(){
	let slider;
	let init = () => {
		slider = new tns({
			container: document.getElementById('main-slider'),
			rewind: true,
			lazyload: true,
			prevButton: document.getElementById('leftArrowClick'),
			nextButton: document.getElementById('rightArrowClick'),
			speed: 500,
			nav: true,
			navPosition: "bottom",
			responsive: {
					600:{
						nav: false
					}
				}
		});
		
		if(document.getElementById('main-slider').parentNode.parentNode.previousElementSibling.classList.contains("tns-liveregion"))
			document.getElementById('main-slider').parentNode.parentNode.previousElementSibling.setAttribute("role", "status");
		
		events();
	}, 
	events = () => {
		slider.events.on('indexChanged', updateBorder);
		
		let thumbSelectImgs = [].slice.call(document.querySelectorAll(".otherImagesJS"));
		thumbSelectImgs.forEach((el) => {
			el.addEventListener("click", update);
		});
		
		let extraProdImgs = [].slice.call(document.querySelectorAll(".DetailsProdImg"));
		extraProdImgs.forEach((el) => {
			el.addEventListener("click", function (e) {
				e.preventDefault();
			});
		});
	},
	updateBorder = () => { 
		let index = slider.getInfo().index;
		let thumbs = [].slice.call(document.querySelectorAll('.thumbSelectImage'));
		thumbs.forEach((el) => {
			el.classList.remove('borderDkGray');
			el.classList.add('borderMdGray');
		});
		let newthumb = document.getElementById('thumbSelectImage_' + index);
		newthumb.classList.remove('borderMdGray');
		newthumb.classList.add('borderDkGray');
	},
	update = e => {  
		let el = e.currentTarget,
		image = el.querySelector("img"),
		vals = image.id.split('_');
		
		slider.goTo(vals[1]);
		
		let allImages = [].slice.call(document.querySelectorAll(".DetailsProdImg"));
		allImages.forEach((el) =>{
			el.setAttribute("tabindex", "-1");
		});
		
		slider.events.on('transitionEnd', function () {
			document.querySelector(".tns-slide-active").querySelector(".DetailsProdImg").setAttribute("tabindex", "0");
			document.querySelector(".tns-slide-active").querySelector(".DetailsProdImg").focus();
		});
	},
	goToFirstSlide = () =>{
		slider.goTo(0);
	};
	
	return{
		Init: init,
		GoToFirstSlide: goToFirstSlide,
		Slider: slider
	};
};