const RestrictedPricing4i = function () {
	const helpers = new formHelpers4i();
	
	let cartUrl, restrictedPricingDialog, productName, productNameNoQuote, gfeedCheck,
 	orderMin, editProdID, common, prodConfig, addItemToCart,

	initVariables = () =>{
		restrictedPricingDialog = new Dialog4i(); 
		cartUrl = "";
		productName = document.querySelector(".prodName").textContent;
		productNameNoQuote = productName.replace(/'/g, "");
		gfeedCheck = document.getElementById("gcheck") ? document.getElementById("gcheck") : "";
		orderMin = document.querySelector('.showpm');
	},
	init = (commonJS) => {     
		initVariables();
		common = commonJS;
		addItemToCart = new addToCart4i(common);
		prodConfig = common.ProductConfig;
		editProdID = prodConfig.EditID;
		const dialogContent = getContent();
		
		restrictedPricingDialog.Init({
			triggerElements: [],
			dialogClass: "restrictedPricing largeDialog", 
			title: '<svg class="textGreen inlineMiddle" id="gcheck" height="30px" width="30px" aria-hidden="true"> <use xlink:href="#svgIcon-checkMark"></use> </svg><span class="inlineMiddle">Added to Cart - View Price</span>',
			content: dialogContent,
			onClose: close
		});
	},
	getContent = () => {
		let htmlContent = 
			'<div class="displayFlex">' +
				'<div class="c-md-2 c-xs-quarter-1" id="imgforrestricted">' + 
					'<img alt="' + productNameNoQuote + '" src="' + document.getElementById("hypProdImg").getAttribute("href") + '" class="responsiveImg" id="restrictedProductImg" />' +
				'</div>' +
				'<p class="c-md-11 c-xs-quarter-3 text16 marginBtm10">The ' + productName + ' has been added to your cart to show the sale price. Sometimes our vendors require us to advertise their products at a suggested price which means when we sell them for less, we only display that price in the cart.</p>' +
			'</div>' +
			'<div class="pricingArea">' +
				'<h3 class="text20 textSemiBold paddingBtm10 paddingLeft10 hideOnSmall"><span class="prodNameRestricted">4imprint Restricted Pricing</span></h3>' +
			'</div>' +
			'<div class="marginTop10 displayFlex flexSpaceBetween flexAlignItemsCenter">' +
				'<a id="btnRemoveCart" href="#" role="button" class="btnRemoveCart text16 noLine darkestLink marginBtm10"><svg class="inlineMiddle" height="25px" width="25px" aria-hidden="true" focusable="false"> <use xlink:href="#svgIcon-trash"></use> </svg> <span class="inlineMiddle">Remove from Cart</span></a>' +
				'<button type="button" id="btnGoToCart" class="btnGoToCart button-lg bkgdBlue text16"><span>Go to Cart <svg class="rotate90" height="12px" width="12px" aria-hidden="true" focusable="false"> <use xlink:href="#svgIcon-arrowSolid"></use> </svg></span></button>' +
			'</div>';

		return htmlContent;
	},

	deleteRestrictedCart = cart => {
		var dcid = document.getElementById('popUpDialog').getAttribute('data-cart-delete');

		fetch('/cart/deleteitem', {
			method: 'POST',
        		credentials: 'same-origin',
			body: JSON.stringify({ cartId: cart, cartItemId: dcid }),
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', '__RequestVerificationToken': helpers.HeaderToken, 'RequestVerificationToken': helpers.HeaderToken  }
		}).then(function (response) {
			return (response.ok) ? response.json() : []; // response.json() returns a promise
		}).then(function (response) {
			//not sure what to do if they remove from cart, I think take them to the cart page for now
			//window.location.href = cartUrl;
			setUpPage();
			restrictedPricingDialog.Close();
			
			GTMLogCartRemoval();
			setTimeout(() =>{
				document.querySelector(".btnAddToCart").focus();
			}, 100);
		});
	},
	open = (response) => {
		const headerLinks = new header4i();
		headerLinks.HeaderCart.Init();
		
		const priceEach = document.querySelector('.showp');
		priceEach.classList.remove('hide');
		orderMin.classList.add('hide');
		document.querySelector('.cartLoadingImg').classList.add('hide');
		
		restrictedPricingDialog.Open();
		
		if (prodConfig.IsApparelItem) insertPriceTable();
		else insertGlide();
		
		document.getElementById("popUpDialog").setAttribute("data-cart-item",  prodConfig.EditID);
		document.getElementById("popUpDialog").setAttribute("data-cart-delete", response.cartItemID);
		
		addEvents(response.cartID);
	},
	insertGlide = () => {
		document.getElementById("popUpDialog").querySelector(".pricingArea").appendChild(document.querySelector('.glide'));
		document.querySelector(".glideToolTip ").style.display = "none";
		document.querySelector(".glide").style.padding = "0px 10px";
	},
	insertPriceTable = () =>{
		const priceTable = document.getElementById('priceTblSizeChoice');
		document.getElementById("popUpDialog").querySelector(".pricingArea").appendChild(priceTable);
		const sizeBoxes = document.getElementById('sizingBox');
		document.getElementById("popUpDialog").querySelector(".pricingArea").appendChild(sizeBoxes);
		priceTable.classList.remove("hide");
		priceTable.classList.add('hide');
		if(document.getElementById('pnlFitRating')) document.getElementById('pnlFitRating').classList.add('hide');
	},
	addEvents = cartID => {		
		const removeBtn = document.getElementById("popUpDialog").querySelector(".btnRemoveCart");
		removeBtn.addEventListener('click', function (e) {
			e.preventDefault();
			deleteRestrictedCart(cartID);
		});
		
		const cartBtn = document.getElementById("popUpDialog").querySelector(".btnGoToCart")
		cartBtn.addEventListener('click', function (e) {
			e.preventDefault();
			e.stopPropagation();
			editProdID = document.getElementById("popUpDialog").getAttribute("data-cart-item"); //temporary fix
			addItemToCart.Send();
			//ajaxActions.AddToCart(e);
		});
	},
	close = () => {
		setUpPage();
		editProdID = -1;
		setTimeout(() =>{
			document.querySelector(".btnAddToCart").focus();
		}, 100);
	},
	setUpPage = () => {
		const headerLinks = new header4i();
		headerLinks.HeaderCart.Init();
		if (prodConfig.IsApparelItem) {
			const sizeBoxes = document.getElementById('sizingBox');
			document.getElementById('pnlSizing').appendChild(sizeBoxes);
		} else {
			document.querySelector('.glidePanel').appendChild(document.querySelector('.glide'));
			document.querySelector(".glideToolTip ").removeAttribute("style");
			document.querySelector(".glide").removeAttribute("style");
		}
		if (gfeedCheck !== "") gfeedCheck.parentNode.removeChild(gfeedCheck);
		
		const priceEach = document.querySelector('.showp');
		priceEach.classList.add('hide');
		orderMin.classList.remove('hide');
		document.querySelector(".goToStep1").focus();
	},
	GTMLogCartRemoval = () => {
		if (typeof dataLayer !== 'undefined' && window.ga) {
			// Measure the removal of a product from a shopping cart.
			dataLayer.push({
				'event': 'removeFromCart',
				'ecommerce': {
					'remove': {
						'products': [{
							'name': document.getElementById('ProductName').value.replace("'", ''),
							'id': document.getElementById('CatalogAlias').value,
							'price': (document.querySelector(".glidePanel") ? document.getElementById('lblPrice').value : document.getElementById('lblPrice').innerHTML).replace(common.CurrencySymbol, ''),
							'brand': document.getElementById('gtmBrand').value,
							'category': document.getElementById('gtmCategory').value,
							'variant': document.getElementById('colorSelected').value,
							'quantity': common.GetQty()
						}]
					}
				}
			});
		}
	};

	return {
		Init: init,
		Open: open
	};
};