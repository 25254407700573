const sliderGlide4i = function () {
	const glide = new Glide4imprint();
	const twentyFourHrLabel = document.getElementById('twentyFourHrLabel').value;
	let glidePrice, glideTotal, glideQuantity, glideSubTotal, glideSummaryShown, glideAmountSaved,
	glideDiscountDiv, glideTotalDiv, glideQtyDiv, glideToolTip, glideWrapper, glideSlider, step2Btn, glideMinPoint,
	glideMaxPoint, singleBreak, priceBusy, glideRunCharge, glideBaseExtras, common, prodConfig, qty,
	ssDetails, txtTimer = null;

	const initVariables = () => {
		glidePrice = document.getElementById('lblPrice');
		glideTotal = document.getElementById("lblTotal");
		glideSubTotal = document.getElementById("lblSubtotal") ? document.getElementById("lblSubtotal") : null;
		glideSummaryShown = document.getElementById("lblBaseExtras") ? true : false;
		glideQuantity = document.getElementById("txtQty");
		glideAmountSaved = document.getElementById("YouSaveBox");
		glideDiscountDiv = document.getElementById("divYouSave");
		glideTotalDiv = document.getElementById("litGlideTotal");
		glideQtyDiv = document.getElementById("lblQuantityTxtCaption");
		glideToolTip = document.querySelector(".glideToolTip");
		glideWrapper = document.querySelector(".glide");
		glideSlider = document.querySelector(".glideSlider");
		step2Btn = document.querySelector(".step1");
		glideMinPoint = glide.MinPoint();
		glideMaxPoint = glide.MaxPoint();
		singleBreak = prodConfig.PriceBreak[2] <= 0;
		priceBusy = '<div class="spinner" aria-live="polite" aria-atomic="true" aria-label="Loading new price"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
		glideRunCharge = document.getElementById("RunCharge") ? document.getElementById("RunCharge").value : 0;
		glideBaseExtras = document.getElementById("lblBaseExtras") ? document.getElementById("lblBaseExtras").innerHTML : 0;
	};
	
	const init = (commonJS) => {
		common = commonJS;
		prodConfig = common.ProductConfig;
		ssDetails = common.SSDetails;
		
		glide.Init(prodConfig.PriceBreak, prodConfig.ArrayPrice, prodConfig.SteppingQty);
	};
	
	let setUpSlider = () => { //init	
		if (prodConfig.IsGlide) {
			initVariables();

			glideToolTip.classList.add("invisible"); // adding to prevent visual jump before styling - can be removed after next release 11/1

			glideSlider.setAttribute("min", glideMinPoint);
			glideSlider.setAttribute("max", glideMaxPoint);
			
			glideSlider.addEventListener("change", updatePrice);
			// firefox and chrome
			glideSlider.addEventListener("input", updatePrice);

			window.addEventListener("resize", function () {
				if (window.innerWidth >= 800) updateToolTip(); //recalculate the tooltip position
			});

			glideQuantity.addEventListener('keyup', function (e) {
				if (common.IsGFeed) {
					if(document.getElementById('GFeedPanel')) { document.getElementById('GFeedPanel').parentNode.removeChild(document.getElementById('GFeedPanel')); }
					glideTotalDiv.classList.remove("hide");
					glideQtyDiv.classList.remove("hide");
					glideWrapper.lastElementChild.classList.remove("hide"); //unit price
					glideWrapper.lastElementChild.classList.add("displayFlex"); //unit price
				}
				if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) { //enter key
					textUpdateSliderFinish(glideQuantity.value, true);
				}else {
					textUpdateSlider(glideQuantity.value, true);
				}
				return false;
			});

			// when using up/down arrows on right side of input
			glideQuantity.addEventListener('change', function () {
				textUpdateSliderFinish(glideQuantity.value, true);
			});

			// If editing update the slider with the appropriate qty
			if (prodConfig.EditQty > 0) {
				if(!prodConfig.IsApparelItem) textUpdateSliderFinish(prodConfig.EditQty, false);
				else common.CalcPrice();


			}
			// updates the tooltip after everything on page has loaded and is in correct position
			setTimeout(() => {
				updateToolTip();
				glideToolTip.classList.remove("invisible"); //show tooltip after set correctly - prevents the visual jump
			}, 600);
		}
	},
	updateToolTip = () => {
		// position
		let val = parseInt(glideSlider.value, 10),
		pos = (val - glideMinPoint) / (glideMaxPoint - glideMinPoint),
		glideThumbWidth = 34,
		thumbCorrect = glideThumbWidth * (pos - 0.5) * -1,
		titlepos = Math.round((pos * glideSlider.offsetWidth) - glideThumbWidth / 4 + thumbCorrect);
		
		glideToolTip.style.left = (titlepos + 20) + "px";

		// content
		let qty = glideSlider.value,
		firstDigit = glide.GetBreakSegment(qty),
		quantity = glide.GetSlideQty(firstDigit, qty);
		glideToolTip.textContent = quantity;

		glideSlider.setAttribute("aria-valuetext", "Quantity selected is " + quantity);
		glideSlider.setAttribute("aria-valuenow", quantity);
	},
	textUpdateSlider = (qty, focus) =>{
		glideTotal.innerHTML = priceBusy;
		
		if (txtTimer != null) clearTimeout(txtTimer);
		
		let updateTime = 3000; // give more time before correcting to lowest qty amount
		if (qty > prodConfig.LowestQuantity) updateTime = 1500;
		txtTimer = setTimeout(() => {
			textUpdateSliderFinish(qty, focus);
		}, updateTime);
	},
	checkForSingleQtyBreak = () =>{
		// The glide slider will be disabled if there is only one break, so need to fill the price each in with the one price
		if (singleBreak) {
			var price = prodConfig.ArrayPrice[1];
			var qty = prodConfig.PriceBreak[1];
			glideQuantity.value = qty;
			glidePrice.value = common.CurrencySymbol + price.toFixed(2);
			glideTotal.innerHTML = common.CurrencySymbol + (price * qty).toFixed(2);
			if (glideSummaryShown) {
				glideSubTotal.innerHTML = common.CurrencySymbol + (price * qty).toFixed(2);
			}
			document.querySelector('.glideWrapper').classList.add('hide');
			document.querySelector("#litGlideInstructions").querySelector(".showOnSmall").innerHTML = "Enter the quantity you'd like.";
			document.querySelector("#litGlideInstructions").querySelector(".hideOnSmall").innerHTML = "Enter the quantity you'd like.";
			if (document.getElementById("litGlidePatent")) {
				document.getElementById("litGlidePatent").classList.add("hide");
				document.getElementById("litGlidePatent").classList.remove("hideOnSmall");
			}
		}
	},
	updatePrice = e => {
		e.preventDefault();
		let qty = glideSlider.value.toString();
		var firstDigit = glide.GetBreakSegment(qty);
		var quantity = glide.GetSlideQty(firstDigit, qty);

		if (common.IsGFeed) {
			var oldquant = prodConfig.IsApparelItem ? parseInt(glideQuantity.innerHTML) : parseInt(glideQuantity.value);
			if (oldquant !== quantity) {
				if (document.getElementById('GFeedPanel')) {
					document.getElementById('GFeedPanel').style.display = "none";
				}
				glideTotalDiv.classList.remove("hide");
				glideQtyDiv.classList.remove("hide");
				glideWrapper.lastElementChild.classList.remove("hide"); //unit price
				glideWrapper.lastElementChild.classList.add("displayFlex"); //unit price
			}
		}
		if (glide.MinBreakQty() <= quantity) {
			if (firstDigit != null) {
				var newPrice = glide.GetPriceEstimate(firstDigit, quantity, qty);
				var startPrice = prodConfig.ArrayPrice[1]; // pricing always starts at index 1

				updateToolTip();

				glidePrice.value = common.CurrencySymbol + newPrice.toFixed(2);
				if (glideSummaryShown && glideSubTotal) {
					glideSubTotal.innerHTML = common.CurrencySymbol + (newPrice * quantity).toFixed(2);
					var extrasTotal = parseFloat(parseFloat(glideBaseExtras) + (quantity * glideRunCharge));
					if(document.getElementById("lblBaseExtras")){ // not there when error getting extra pop up
						document.getElementById("lblBaseExtras").innerHTML = common.CurrencySymbol + extrasTotal.toFixed(2);
					}
					glideTotal.innerHTML = common.CurrencySymbol + ((newPrice * quantity) + parseFloat(extrasTotal)).toFixed(2);
				} else {
					glideTotal.innerHTML = common.CurrencySymbol + (newPrice * quantity).toFixed(2);
				}
				glideQuantity.value = quantity;

				if (Math.round(newPrice * 100) >= Math.round(startPrice * 100)) {
					if (!glideAmountSaved.classList.contains("invisible")) glideAmountSaved.classList.add("invisible");
				} else {
					if (glideAmountSaved.classList.contains("invisible")) glideAmountSaved.classList.remove("invisible");
					
					glideDiscountDiv.innerHTML = (common.CurrencySymbol + ((startPrice * quantity) - (newPrice * quantity)).toFixed(2));
				}
				if (common.IsMobile) {
					if (document.getElementsByClassName("quantitySummary")[0]) {
						document.getElementsByClassName("quantitySummary")[0].innerHTML = common.GetQty();
						if (document.getElementsByClassName("quantitySummary")[1]) {
							document.getElementsByClassName("quantitySummary")[1].innerHTML = document.getElementsByClassName("quantitySummary")[0].innerHTML;
						}
						if (prodConfig.IsSingleShipItem) ssDetails.FormEvents.ConfigureSSBoxesSection();
					}
					if (document.getElementsByClassName("prodColor")[0] && document.getElementsByClassName("prodColor")[0].innerHTML != "" && document.getElementsByClassName("prodColor")[0].innerHTML.indexOf("Please select your ") == -1) {
						common.GetSelectedImprintColors();
						if (prodConfig.ListSelectedColors.length > 0) common.GetExtrasAmount();
						else common.GetExtrasAmount(true);
					}
				}
			}
		}
	},
	triggerChange = () => {
		var event = document.createEvent("Event");
		event.initEvent("change", true, true);
		glideSlider.dispatchEvent(event);
	},
	/*enableBtn = () => {
		if (step2Btn) {
			step2Btn.classList.remove('disabledBtn');
			step2Btn.classList.add('bkgdBlue');
			step2Btn.classList.add('whiteLink');
		} else if (common.BypassImpConfig) {
			document.querySelector('.btnAddToCart').classList.remove('disabledBtn');
			document.querySelector('.btnAddToCart').classList.remove('bkgdMdGray');
			document.querySelector('.btnAddToCart').classList.add('bkgdRed');
			document.querySelector('.btnAddToCart').classList.add('whiteLink');
		}
	},*/
	textUpdateSliderFinish = (quantity, focus) =>{
		quantity = Math.round(quantity); // if decimals are used in the input box
		if (quantity == "" || quantity < 0) { //enforce min quantity
			glideQuantity.value = prodConfig.LowestQuantity;
			glideSlider.value = glide.SetSlideQty(prodConfig.LowestQuantity);
			triggerChange();
		}
		if (glide.IsNumeric(quantity)) {
			//enableBtn();
			qty = parseInt(quantity);
			var SlideQty = qty;
			var quantBoxValue = glideQuantity.value;
			var price;
			if (prodConfig.LowestQuantity > qty) qty = prodConfig.LowestQuantity;  //enforce min quantity   	

			//enforce max quantity
			if (common.Restrict24hrMaxQty && qty != glide.MaxBreakQty() && document.getElementById("twentyFourAlert")) {
				document.getElementById("twentyFourAlert").parentNode.removeChild(document.getElementById("twentyFourAlert"));
			}

			if (common.Restrict24hrMaxQty && qty > glide.MaxBreakQty()) {
				const maxBreak = glide.MaxBreakQty();
				qty = maxBreak;
				glideQuantity.value = maxBreak;
				quantBoxValue = maxBreak;

				var quantityErrorHtml =
					'<div class=\"alertBoxFlex displayFlex flexNoWrap\" id="twentyFourAlert"><svg class="alertBulb" height="25px" width="25px" aria-hidden="true"><use xlink: href="#svgIcon-hint"></use></svg><div class="alertText">We\'re sorry, the ' + twentyFourHrLabel + ' service quantity limit is ' + glide.MaxBreakQty() + ' for this item. The amount you entered has been adjusted. <a href="' + prodConfig.StandardProdTimeProductUrl + '">Shop item #' + prodConfig.StandardProdTimeCatalogueAlias + ' with a standard production time</a> for higher quantities<br/></div></div>';
				document.querySelector('.glideWrapper').insertAdjacentHTML('beforebegin', quantityErrorHtml);
			}

			// Only update the slider if there are multiple qty breaks
			if (singleBreak) { // example: /product/6289/Soft-Carrying-Case
				price = glidePrice.value.trim().substring(1); // Trims the currency symbol
				glideTotal.innerHTML = (common.CurrencySymbol + (price * qty).toFixed(2));
			} else {
				var lastBreakQty = glide.MaxBreakQty();
				// glideMaxPoint contains the maximum slide val and not quantity, cannot accurately compare the two - requires a conversion
				if (qty > lastBreakQty) { // If the qty is greater than the end of the slider, need to set the value to slide to to the last break
					glideSlider.value = glide.SetSlideQty(lastBreakQty);
					triggerChange();
				} else {
					//glideSlider.dispatchEvent(new Event('change')); // does not work in IE 11
					glideSlider.value = glide.SetSlideQty(quantBoxValue);
					triggerChange();
				}
			}
			// The slider doesn't deal in qtys, just percentages, so sometimes it will adjust the qty when it does
			// its percentage calculation if the user typed a qty. This will reset it to the user entered value.
			if (qty > SlideQty) {
				glideQuantity.value = qty;
				glideToolTip.textContent = qty;
				price = glidePrice.value.trim().substring(1); // Trims the currency symbol
				glideTotal.innerHTML = (common.CurrencySymbol + (price * qty).toFixed(2));
				if (glideSummaryShown) {
					glideSubTotal.innerHTML = common.CurrencySymbol + (price * qty).toFixed(2);
					var extrasTotal = parseFloat(parseFloat(glideBaseExtras) + (qty * glideRunCharge));
					if(document.getElementById("lblBaseExtras")){ document.getElementById("lblBaseExtras").innerHTML = common.CurrencySymbol + extrasTotal.toFixed(2); }
					glideTotal.innerHTML = common.CurrencySymbol + ((price * qty) + parseFloat(extrasTotal)).toFixed(2);
				}
			} else {
				if (parseInt(glideQuantity.value) != qty) {
					const enforcedStepQty = glide.EnforceSteppingQty(qty, prodConfig.SteppingQty);
					if (enforcedStepQty == qty) {
						glideQuantity.value = qty;
						glideToolTip.textContent = qty;
						price = glidePrice.value.trim().substring(1);
						glideTotal.innerHTML = (common.CurrencySymbol + (price * qty).toFixed(2));
						if (glideSummaryShown) {
							glideSubTotal.innerHTML = common.CurrencySymbol + (price * qty).toFixed(2);
							var extrasTotal = parseFloat(parseFloat(glideBaseExtras) + (qty * glideRunCharge));
							if(document.getElementById("lblBaseExtras")) { document.getElementById("lblBaseExtras").innerHTML = common.CurrencySymbol + extrasTotal.toFixed(2); }
							glideTotal.innerHTML = common.CurrencySymbol + ((price * qty) + parseFloat(extrasTotal)).toFixed(2);
						}
					} else {
						glideSlider.value = glide.SetSlideQty(enforcedStepQty);
						triggerChange();
					}
				}
			}
		}
	};
	
	const setGlide24HrLimit = (qty1, qty2) => {
		common.Exceeds24hrQtyLimit = qty1 > glide.MaxBreakQty() || qty2 > glide.MaxBreakQty();
	};
	
	const get24Error = (warningId) => {
		const minGlide = glide.MaxBreakQty();
		const error = 
			`<div class="alertBoxFlex displayFlex flexNoWrap" id="${warningId}">
				<svg class="alertBulb" height="25px" width="25px" aria-hidden="true"><use xlink: href="#svgIcon-hint"></use></svg>
				<div class="alertText">We're sorry, the ${twentyFourHrLabel} service quantity limit is ${minGlide} for this item. Please adjust the quantities accordingly. <a href="${prodConfig.StandardProdTimeProductUrl}">Shop item #${prodConfig.StandardProdTimeCatalogueAlias} with a standard production time</a> for higher quantities.</div>
			</div>
		`;
		return error;
	};
	
	const getPrice = (BreakNumBelow, TotalQty, qty) => {
		return glide.GetPriceEstimate(BreakNumBelow, TotalQty, qty);
	};			
	
	const getMaxBreak = () => {
		return glide.MaxBreakQty();
	};
	
	    
	return {
		Init: init,
		SetUpSlider: setUpSlider,
		CheckForSingleQtyBreak: checkForSingleQtyBreak,
		UpdateToolTip: updateToolTip,
		SetGlide24HrLimit: setGlide24HrLimit, //test
		Get24Error: get24Error, //test
		GetPrice: getPrice,
		GetMaxBreak: getMaxBreak //test
	};
};