// tiny slider documentation https://github.com/ganlanyuan/tiny-slider
// prodScroller.Init("sliderID", 4, 3, 2);

var prodScroller = function () {  	
	// container ID, # items on 1024px, # items on 800px, # items on 480px
	let init = (containerID, numberXL, numberLarge, numberMedium) =>{
		const sliderWrapper = document.getElementById(containerID);
		const slider = sliderWrapper.querySelector('.Scroller');
		const previousBtn = sliderWrapper.querySelector('.slider-prev');
		const nextBtn = sliderWrapper.querySelector('.slider-next');
		const liveRegion = sliderWrapper.querySelector(".tns-liveregion");
		let tinySlider;
		if(slider){
			tinySlider = new tns({
				container: slider,
				lazyload: true,
				prevButton: previousBtn,
				nextButton: nextBtn,
				items: 1, // number items on mobile
				gutter: 10, // space between slides
				slideBy: "page",
				nav: false,
				loop: false,
				arrowKeys: true,
				responsive: {
					1024:{ items: numberXL },
					800: { items: numberLarge },
					480: { items: numberMedium }
				}
			});
			
			if(slider.classList.contains("invisible")) slider.classList.remove("invisible");
			
			//fixing missing role in third party script
			if(liveRegion) liveRegion.setAttribute("role", "status");
		}
		return tinySlider; 
	};
	
	return{
		Init: init
	};
};