/*
    Copyright 2009-present (c) 4imprint Inc. All Rights Reserved.
    Glide control that depends on this code and algorithm contained below are protected by Pat. No. 7,979,318.
*/

var Glide4imprint = function() {
	var arrQtyBreaks = [],
		arrPriceBreaks = [],
		minSliderPoint = 0,
		maxSliderPoint = 0,
		HighBreakQty = 0,
		LowBreakQty = 0,
		steppingQty = 1,
	
	// The first digit detirmines what price break we're in
	BreakSegment = function (sliderVal)
	{
	    if(IsNumeric(sliderVal))
	    {
	        if(sliderVal.length > 3)
	        {
	            return parseInt(sliderVal.substring(0, 2));
	        }
	        else if(sliderVal.length > 2)
	        {
	            return parseInt(sliderVal.charAt(0));
	        }
	        else
	        {
	            return 0;
	        }
	    }
	    else
	    {
	        return null;
	    }
	},
	
	SlideQty = function (breakNumber, sliderVal)
	{
	    var segmentEnd = breakNumber + 1;    
	    var sliderPosition = parseInt(sliderVal);
	    
	    // If quantity chosen is beyond the last break, return the last break, or is the min qty
	    if(segmentEnd >= arrQtyBreaks.length || arrQtyBreaks[segmentEnd] < 1){ 
	        return arrQtyBreaks[breakNumber];
	    } else {        
			var qtyDiffTweenBreaks = parseInt(arrQtyBreaks[segmentEnd] - arrQtyBreaks[breakNumber]);
			
			// trim first char off slider val gets percent between breaks(percentileQtyLine)
			if(sliderVal >= 100) {
				var percentileQtyLine = parseInt(sliderVal.substring(breakNumber.toString().length));
			} else {
				var percentileQtyLine = parseInt(sliderVal);
			}
			var quantity = arrQtyBreaks[breakNumber] + Math.round((qtyDiffTweenBreaks * percentileQtyLine) / 100); // Math.round is probably not needed here, left just in case
			quantity = enforceSteppingQty(quantity, steppingQty);
			if (quantity >= arrQtyBreaks[segmentEnd]) { quantity = quantity - steppingQty; }
			return quantity;
	    }
	},
	
	EstimatePrice = function (breakNumber, qty, sliderVal)
	{          
	    var segmentEnd = breakNumber + 1;    
	    var sliderPosition = parseInt(sliderVal);
	
	    // If quantity chosen is beyond the last break, return the last break
	    if(segmentEnd >= arrPriceBreaks.length || arrPriceBreaks[segmentEnd] < 0.01){ 
	        return (Math.round(arrPriceBreaks[breakNumber] * 100)  / 100);
	    } else {
	        	if(qty == arrQtyBreaks[segmentEnd]) { return (Math.round(arrPriceBreaks[segmentEnd] * 100) / 100); }
			if(qty == arrQtyBreaks[breakNumber]) { return (Math.round(arrPriceBreaks[breakNumber] * 100) / 100); }
	        	var qtyDiffTweenBreaks = parseInt(arrQtyBreaks[segmentEnd] - arrQtyBreaks[breakNumber]);
	        var priceDiffTweenBreaks = parseInt(Math.round(arrPriceBreaks[breakNumber] * 10000) - Math.round(arrPriceBreaks[segmentEnd] * 10000)); // 10000 for 4 decimal places
	        	var qtyMinusLastBreak = parseInt(qty - arrQtyBreaks[breakNumber]);
	        var percentileQtyLine = Math.round((qtyMinusLastBreak * 100) / qtyDiffTweenBreaks); 
	        //var percentileQtyLine = parseFloat(sliderVal.substring(breakNumber.toString().length)) / 100;
	        var finalPrice;
	        
	        if(breakNumber == 1) {
	            var iLineAdjustQty;
	            var iLinePrice;
	            // Here's the adjustment pin
	            var iPinQtyPercentile = 50;
	            var iPinPricePercentile = 90;
	            
	            // % along the qty field between first two breaks
	            iLineAdjustQty = Math.round((qtyDiffTweenBreaks * iPinQtyPercentile)  / 100) + arrQtyBreaks[breakNumber]; // Math.round needed for lower qty items
	            
	            // % of the first price to charge at this point
	            iLinePrice = ((priceDiffTweenBreaks * iPinPricePercentile) / 100) + Math.round(arrPriceBreaks[segmentEnd] * 10000);  // 10000 for 4 decimal places 
	
	            // Are we to the left or right of the pinned qty?
	            if (qty == iLineAdjustQty)
	            {
	                // We're right on the adjustment pin
	                finalPrice = iLinePrice;
	            } else if (qty < iLineAdjustQty) {
	                // We're below the pinned qty
	                qtyDiffTweenBreaks = parseInt(iLineAdjustQty - arrQtyBreaks[breakNumber]);              // The number of items between the pin qty and lower break    
	                priceDiffTweenBreaks = parseInt(Math.round(arrPriceBreaks[breakNumber] * 10000) - iLinePrice);              // The price difference between the lower break price and pin price
	                // qtyMinusLastBreak = parseInt(qty - arrQtyBreaks[breakNumber]);                       // Commented out because this calculation is the same as the original above
	                percentileQtyLine = Math.round(((qtyMinusLastBreak * 100) / qtyDiffTweenBreaks) * 100) / 100; // This is how far along the quantity line we are between the lower break and the pin qty
	                finalPrice = Math.round(arrPriceBreaks[breakNumber] * 10000) - ((priceDiffTweenBreaks * percentileQtyLine) / 100);
	            } else {
	                // We're above the pinned value
	                qtyDiffTweenBreaks = parseInt(arrQtyBreaks[segmentEnd] - iLineAdjustQty);             // The number of items between the next break and pin qty
	                priceDiffTweenBreaks = parseInt(iLinePrice - Math.round(arrPriceBreaks[segmentEnd] * 10000));             // The price difference between the pin price and next break price
	                qtyMinusLastBreak = parseInt(qty - iLineAdjustQty);                                 // The number of items ABOVE the pin qty the user has chosen
	                percentileQtyLine = Math.round(((qtyMinusLastBreak * 100) / qtyDiffTweenBreaks) * 100) / 100; // This is how far along the quantity line we are between the pin qty and the next break
	                finalPrice = iLinePrice - ((priceDiffTweenBreaks * percentileQtyLine) / 100);
	            }            
	            
	        } else {
	            finalPrice = Math.round(arrPriceBreaks[breakNumber] * 10000) - ((priceDiffTweenBreaks * percentileQtyLine) / 100);
	        }
	        finalPrice = ((Math.round(parseFloat(finalPrice) / 100)) / 100); // Move the decimal over 2 places and round to the nearest cent, then move two more places for proper formatting
	        if (finalPrice <= arrPriceBreaks[segmentEnd]) { finalPrice = (Math.round(finalPrice * 100 + 1) / 100); }
	        return finalPrice;
	    }
	},
	
	GlideInit = function (qtyBreaks, prcBreaks, stepQty) {
		arrQtyBreaks = qtyBreaks;
		arrPriceBreaks = prcBreaks;
		steppingQty = stepQty;
		
		// calc the min and max slider points
		CalculateEndPoints();
	},
	
	setSlideQty = function (qty) {
	    var SliderValue = -1;
	    var BreakNumBelow = -1;
	    var BreakNumAbove = -1;
	    
	    for(var i=0; i < arrQtyBreaks.length; i++)
	    {
	        if((i+1 >= arrQtyBreaks.length || arrQtyBreaks[i+1] < 1) && qty >= arrQtyBreaks[i] && arrQtyBreaks[i] > 0) {
	            BreakNumBelow = i;
	            BreakNumAbove = i;
	        } else {
	            if(qty >= arrQtyBreaks[i] && qty < arrQtyBreaks[i+1]) {
	                BreakNumBelow = i;
	                BreakNumAbove = i + 1;
	            }
	        }
	    }
	    
	    if(qty == arrQtyBreaks[BreakNumBelow]) {
	        return BreakNumBelow * 100;
	    } else {
	        var qtyDiffTweenBreaks = parseInt(arrQtyBreaks[BreakNumAbove] - arrQtyBreaks[BreakNumBelow]);
	        var qtyMinusLastBreak = parseInt(qty - arrQtyBreaks[BreakNumBelow]);
	        var percentileQtyLine = Math.round((qtyMinusLastBreak * 100) / qtyDiffTweenBreaks);
	        //percentileQtyLine = Math.round(percentileQtyLine / 10);
		if (percentileQtyLine.toString().length < 2) {
	            percentileQtyLine = '0' + percentileQtyLine; // space it with a zero if necessary
	        } else if (percentileQtyLine == 100) {
	            percentileQtyLine = 99; // if the round above has gone to 100, subtract one or this function will return a wildly inaccurate value
	        }
	        return parseInt(BreakNumBelow.toString() + percentileQtyLine.toString());
	    }
	},
	
	enforceSteppingQty = function (Qty, StepQty) {
	    var quantity = Qty;
	    var mod = Qty % StepQty;
	    // Doesn't cope with whether the stepping qty may cause it to jump over a qty break
	    if(mod > 0) {            
	        var goUpOrDown = Math.round(mod / StepQty); // gets the percent of the way between the stepping qty to detirmine whether to round up (1) or down (0) 
	        if(goUpOrDown > 0) {
	            quantity = Qty + (StepQty - mod);
	        } else {
	            quantity = Qty - mod;
	        }
	    }
	    return quantity;
	},
	
	StandardPrice = function (breakNumber, qty) {
	    var segmentEnd = breakNumber + 1;
	    if (segmentEnd >= arrQtyBreaks.length || arrQtyBreaks[segmentEnd] < 1) {
	        return arrPriceBreaks[breakNumber];
	    } else if (arrQtyBreaks[segmentEnd] == qty) {
	        return arrPriceBreaks[segmentEnd];
	    } else {
	        return arrPriceBreaks[breakNumber];
	    }
	},
	
	SetLowBreak = function () {
            for(var i=0; i < arrQtyBreaks.length; i++)
            {
                if(arrQtyBreaks[i] > 0){ 
                    //$('#' + lowestQtyHidden).value = arrQtyBreaks[i];
                    GlideMinLabel.innerHTML = arrQtyBreaks[i];
                    break; 
                }
            }                
        },
    
        SetHighBreak = function () {
            for(var i = arrQtyBreaks.length - 1; i >= 0; i --) {
                if(arrQtyBreaks[i] > 0){ 
                    GlideMaxLabel.innerHTML = arrQtyBreaks[i];
                    break; 
                }
            }
        },
        
        CalculateEndPoints = function () {
            maxSliderPoint = -1;

            if (arrQtyBreaks[7] > 0 && arrPriceBreaks[7] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 700;
                HighBreakQty = arrQtyBreaks[7];
            } else if (arrQtyBreaks[6] > 0 && arrPriceBreaks[6] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 600;
                HighBreakQty = arrQtyBreaks[6];
            } else if (arrQtyBreaks[5] > 0 && arrPriceBreaks[5] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 500;
                HighBreakQty = arrQtyBreaks[5];
            } else if (arrQtyBreaks[4] > 0 && arrPriceBreaks[4] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 400;
                HighBreakQty = arrQtyBreaks[4];
            } else if (arrQtyBreaks[3] > 0 && arrPriceBreaks[3] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 300;
                HighBreakQty = arrQtyBreaks[3];
            } else if (arrQtyBreaks[2] > 0 && arrPriceBreaks[2] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 200;
                HighBreakQty = arrQtyBreaks[2];
            } else if (arrQtyBreaks[1] > 0 && arrPriceBreaks[1] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 100;
                HighBreakQty = arrQtyBreaks[1];
            } else if (arrQtyBreaks[0] > 0 && arrPriceBreaks[0] > 0 && maxSliderPoint == -1) {
                maxSliderPoint = 0;
                HighBreakQty = arrQtyBreaks[0];
                return;
            } else {
                // There are no valid price breaks. Hide this control or strange pricing will be displayed.
                maxSliderPoint = 100;
                return;
            }

            // With the new data structure, the MinPoint should always be 0. --SML 2/11/2009
            minSliderPoint = -1;
            if (arrQtyBreaks[0] > 0 && arrPriceBreaks[0] > 0 && minSliderPoint == -1) {
                minSliderPoint = 0;
                LowBreakQty = arrQtyBreaks[0];
            } else if (arrQtyBreaks[1] > 0 && arrPriceBreaks[1] > 0 && minSliderPoint == -1) {
                minSliderPoint = 100;
                LowBreakQty = arrQtyBreaks[1];
            } else if (arrQtyBreaks[2] > 0 && arrPriceBreaks[2] > 0 && minSliderPoint == -1) {
                minSliderPoint = 200;
                LowBreakQty = arrQtyBreaks[2];
            } else if (arrQtyBreaks[3] > 0 && arrPriceBreaks[3] > 0 && minSliderPoint == -1) {
                minSliderPoint = 300;
                LowBreakQty = arrQtyBreaks[3];
            } else if (arrQtyBreaks[4] > 0 && arrPriceBreaks[4] > 0 && minSliderPoint == -1) {
                minSliderPoint = 400;
                LowBreakQty = arrQtyBreaks[4];
            } else if (arrQtyBreaks[5] > 0 && arrPriceBreaks[5] > 0 && minSliderPoint == -1) {
                minSliderPoint = 500;
                LowBreakQty = arrQtyBreaks[5];
            } else if (arrQtyBreaks[6] > 0 && arrPriceBreaks[6] > 0 && minSliderPoint == -1) {
                minSliderPoint = 600;
                LowBreakQty = arrQtyBreaks[6];
            } else if (arrQtyBreaks[7] > 0 && arrPriceBreaks[7] > 0 && minSliderPoint == -1) {
                minSliderPoint = 700;
                LowBreakQty = arrQtyBreaks[7];
            } else {
                // There's no valid price breaks. Hide this control or it will display strange pricing.
                minSliderPoint = 0;
            }
        },
        
	IsNumeric = function (strString) {
	    var strValidChars = "0123456789";
	    var strChar;
	    var blnResult = true;	
	
	    for (var i = 0; i < strString.length && blnResult == true; i++) {
	        strChar = strString.charAt(i);
	        if (strValidChars.indexOf(strChar) == -1) {
	            blnResult = false;
	        }
	    }
	    return blnResult;
	},
	
	BreakArray = function() { return arrQtyBreaks; },
	PriceArray = function() { return arrPriceBreaks; },
	MinPoint = function() { return minSliderPoint; },
	MaxPoint = function() { return maxSliderPoint; },
	MaxBreakQty = function() { return HighBreakQty; },
	MinBreakQty = function() { return LowBreakQty; },
	SteppingQty = function() { return steppingQty; };

	return {
		GetBreakSegment: BreakSegment,
		GetSlideQty: SlideQty,
		GetPriceEstimate: EstimatePrice,
		SetSlideQty: setSlideQty,
		EnforceSteppingQty: enforceSteppingQty,
		GetStandardPrice: StandardPrice,
		BreakArray: BreakArray,
		PriceArray: PriceArray,
		MinPoint: MinPoint,
		MaxPoint: MaxPoint,
		MaxBreakQty: MaxBreakQty,
		MinBreakQty: MinBreakQty,
		SteppingQty: SteppingQty,
		IsNumeric: IsNumeric,
		Init: GlideInit
	};
};